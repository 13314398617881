/* ============================================ *
 * Cookies
 * ============================================ */

.notice-cookie {
    .notice-inner {
        padding-bottom: 0;

        &:after {
            @include clearfix;
        }
    }

    .notice-text {
        float: left;
        max-width: 90%;
        padding-top: 4px;
        text-align: left;

        p {
            padding-bottom: (40px - $f-size-xxs) / 2;
            line-height: 1.3;
        }
    }

    .actions {
        float: left;
        text-align: right;
        padding-bottom: (40px - $f-size-xxs) / 2;
    }
}

// ---------------------------------------------

@include bp(max-width, $bp-large) {
    .notice-cookie {
        .notice-text {
            max-width: 86%;
        }
    }
}

// ---------------------------------------------

@include bp(max-width, $bp-medium) {

    .notice-cookie {
        padding: 0 20px;

        .notice-text {
            max-width: 82%;
            padding-top: 0;
        }
    }
}

// ---------------------------------------------

@include bp(max-width, 620px) {

    .notice-cookie {
        padding: 0 20px;

        .notice-inner {
            background-position: left 10px;

            &:after {
                @include clearfix;
            }
        }

        .notice-text {
            float: none;
            max-width: 100%;
            padding-top: 0;
        }

        .actions {
            text-align: left;
        }
    }
}
