.cms-como-comprar {
    .main-container{
        padding: 15px 0;
        @include bp(max-width, $xs-bootstrap){
            padding: 5px 0px;
        }
        .font-weight-bold{
            .row{
                border-bottom: 1px solid lightgray;
                margin-right: 0px;
                margin-left: 0px;
                .col-auto{
                    padding-left: 0;
                }
            }
        }
    }
    .std{
        width: 100%;
        .container{
            @include bp(max-width, $xs-bootstrap){
                padding: 0px;
            }
        }
        
    }
    h2{
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        color: $c-gray;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0px;
        text-align: left;
        padding: 0;
        border-bottom: 4px solid $primary-color;
    }
    .breadcrumbs {
        margin: 0px 28px 5px;
        @include bp(max-width, $xs-bootstrap){
            margin: 15px 15px 5px;
        }   
    }
    .block-titulo {
        h2 {
            font-weight: 700;
        }
    }
    h3 {
        font-size: 30px;
        color: $c-gray;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: capitalize;
    }
    .btn {
        width: 217px;
        height: 50px;
        line-height: 36px;
        font-size: 15px;
        font-weight: 400;
        text-transform: uppercase;
        color: white;
        @media only screen and (max-width: 1007px) {
            width: 160px;
        }
        @media only screen and (max-width: 500px) {
            width: 130px;
        }
        @include bp(max-width, $xs-bootstrap){
            width: 48%;
            padding-left: 15px;
            font-size: 1rem;
            margin: 0;
            padding: 0;
            margin-right: 0 !important;
        }
  
        &.prevBtn {
            background: #B4B4B4;
            @include bp(max-width, $xs-bootstrap){
                float: left !important;
            }
            i{
                margin-right: 10px;
            }
        }
        &.nextBtn{
            i{
                margin-left: 10px;
            }
        }
    }

    .setup-content {
        @include bp(max-width, $xs-bootstrap){
            margin: 0;
            padding: 0 15px;
        }
        .pass-text {
            @include bp(max-width, $xs-bootstrap){
                margin-top: 5px;
                min-height: 175px;
            }
        }
        img{
            border-bottom-left-radius: 15px;
            border-top-right-radius: 15px;
            border: 1px solid lightgray;
        }
        .img-step-content{
            @include bp(max-width, $xs-bootstrap){
                padding: 0;
                margin-bottom: 20px;
            }
        }
        .nav-how-container{
            @include bp(max-width, $xs-bootstrap){
                justify-content: space-between!important;
            }
        }
    }

    .header {
        h1 {
            color: rgba(68, 68, 68, 1.0);
            font-family: 'Lato', Helvetica, Arial, serif;
            font-weight: 900;
            font-style: normal;
            font-size: 36px;
            color: rgba(68, 68, 68, 1.0);
            text-align: center;
            line-height: 45.0px;

            &::before {
                display: inline-block;
                content: "";
                border-top: .1rem solid rgba(68, 68, 68, 1.0);
                width: 20rem;
                margin-right: 2rem;
                transform: translateY(-1rem);
            }

            &::after {
                display: inline-block;
                content: "";
                border-top: .1rem solid rgba(68, 68, 68, 1.0);
                width: 20rem;
                margin-left: 2rem;
                transform: translateY(-1rem);
            }
        }
    }

    .texto-n {
        font-weight: 300;
        font-style: normal !important;
        color: $c-gray;
        font-size: 14px;
        line-height: inherit;
        margin-top: 20px;
    }

    .contenido {
        margin-top: 50px;
        margin-bottom: 50px;
        z-index: 1;
        padding: 0;
        .titulo {
            h2 {
                font-family: 'Lato', Helvetica, Arial, serif;
                font-weight: 700;
                font-style: normal;
                font-size: 24.0px;
                color: $c-gray;
                text-align: left;
                line-height: 35.0px;
            }
        }

        p {
            font-family: 'Lato', Helvetica, Arial, serif;
            font-weight: 300;
            font-style: normal;
            font-size: 14px;
            color: $c-gray;
            text-align: left;
        }

        .stepwizard-step {
            display: table-cell;
            text-align: center;
            position: relative;
            background: white;

            p {
                margin-top: 10px;
            }

            button[disabled] {
                opacity: 1 !important;
                filter: alpha(opacity=100) !important;
            }
        }

        .stepwizard-row {
            display: table-row;


        }

        .stepwizard {
            display: table;
            width: 100%;
            position: relative;
        }

        .paso {
            pointer-events: none;
            margin: 0 auto;
            display: block;
            width: 40px;
            height: 40px;
            text-align: center;
            padding: 4px 0;
            font-size: 20px;
            border-radius: 100%;
            background: lightgray;
            color: #FFF;
            font-weight: 900;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-decoration: none;
            z-index: 9999;
            transition: all 0.3s;
            &:before {
                top: 50%;
                bottom: 0;
                position: absolute;
                content: " ";
                width: 32vw;
                right: 0vw;
                height: 2px;
                background-color: lightgray;
                z-index: -2;
                transition: all 0.5s;
                @media only screen and (min-width: 1400px) {
                    width: 27vw;
                    right: 2vw;
                }
                @media only screen and (min-width: 1650px) {
                    width: 22vw;
                }
            }
            &.activo{
                background: $primary-color;
                width: 50px;
                height: 50px;
                padding: 10px 0;
                z-index: -1;
                &:before {
                    width: 32vw;
                    background-color: $c-gray;
                    height: 4px;
                    transition: all 0.3s;
                    @media only screen and (min-width: 1400px) {
                        width: 27vw;
                        right: 2vw;
                    }
                    @media only screen and (min-width: 1650px) {
                        width: 22vw;
                    }
                }
            }
            

            &#paso-1 {
                &:before {
                    content: none;
                }
            }
        }

        @media only screen and (min-width: 1400px) {
            .breadcrumbs {
                margin: 15px 0;
            }

            .header {
                h1 {
                    color: rgba(68, 68, 68, 1.0);
                    font-family: 'Lato', Helvetica, Arial, serif;
                    font-weight: 900;
                    font-style: normal;
                    font-size: 36px;
                    color: rgba(68, 68, 68, 1.0);
                    text-align: center;
                    line-height: 45.0px;

                    &::before {
                        display: inline-block;
                        content: "";
                        border-top: .1rem solid rgba(68, 68, 68, 1.0);
                        width: 21rem;
                        margin-right: 2rem;
                        transform: translateY(-1rem);
                    }

                    &::after {
                        display: inline-block;
                        content: "";
                        border-top: .1rem solid rgba(68, 68, 68, 1.0);
                        width: 21rem;
                        margin-left: 2rem;
                        transform: translateY(-1rem);
                    }
                }
            }

            .contenido {
                margin-top: 50px;
                margin-bottom: 50px;

                .titulo {
                    h2 {
                        font-family: 'Lato', Helvetica, Arial, serif;
                        font-weight: 700;
                        font-style: normal;
                        font-size: 24.0px;
                        color: rgba(0, 0, 0, 1.0);
                        text-align: left;
                        line-height: 35.0px;
                    }
                }

                p {
                    font-family: 'Lato', Helvetica, Arial, serif;
                    font-weight: 300;
                    font-style: normal;
                    font-size: 17.0px;
                    color: rgba(0, 0, 0, 1.0);
                    text-align: justify;
                    line-height: 34.0px;
                }
            }
        }

        @media only screen and (max-width: 1200px) {
            .breadcrumbs {
                margin: 15px;
            }

            .header {
                h1 {
                    color: rgba(68, 68, 68, 1.0);
                    font-family: 'Lato', Helvetica, Arial, serif;
                    font-weight: 900;
                    font-style: normal;
                    font-size: 37px;
                    text-align: center;
                    line-height: 45.0px;

                    &::before {
                        display: inline-block;
                        content: "";
                        border-top: .1rem solid rgba(68, 68, 68, 1.0);
                        width: 23rem;
                        margin-right: 2rem;
                        transform: translateY(-1rem);
                    }

                    &::after {
                        display: inline-block;
                        content: "";
                        border-top: .1rem solid rgba(68, 68, 68, 1.0);
                        width: 23rem;
                        margin-left: 2rem;
                        transform: translateY(-1rem);
                    }
                }
            }

            .contenido {
                margin-top: 50px;
                margin-bottom: 50px;

                .titulo {
                    h2 {
                        font-family: 'Lato', Helvetica, Arial, serif;
                        font-weight: 700;
                        font-style: normal;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 1.0);
                        text-align: left;
                        line-height: 20px;
                    }
                }

                p {
                    font-family: 'Lato', Helvetica, Arial, serif;
                    font-weight: 300;
                    font-style: normal;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 1.0);
                    text-align: justify;
                    line-height: 20px;
                }
            }
        }

        @media only screen and (max-width: 804px) {
            .breadcrumbs {
                margin: 15px;
            }

            .header {
                h1 {
                    color: rgba(68, 68, 68, 1.0);
                    font-family: 'Lato', Helvetica, Arial, serif;
                    font-weight: 900;
                    font-style: normal;
                    font-size: 22px;
                    text-align: center;
                    line-height: 45.0px;

                    &::before {
                        display: inline-block;
                        content: "";
                        border-top: .1rem solid rgba(68, 68, 68, 1.0);
                        width: 3rem;
                        margin-right: 2rem;
                        transform: translateY(-1rem);
                    }

                    &::after {
                        display: inline-block;
                        content: "";
                        border-top: .1rem solid rgba(68, 68, 68, 1.0);
                        width: 3rem;
                        margin-left: 2rem;
                        transform: translateY(-1rem);
                    }
                }
            }

            .contenido {
                margin-top: 50px;
                margin-bottom: 50px;

                .titulo {
                    h2 {
                        font-family: 'Lato', Helvetica, Arial, serif;
                        font-weight: 700;
                        font-style: normal;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 1.0);
                        text-align: left;
                        line-height: 20px;
                    }
                }

                p {
                    font-family: 'Lato', Helvetica, Arial, serif;
                    font-weight: 300;
                    font-style: normal;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 1.0);
                    text-align: justify;
                    line-height: 20px;
                }
            }
        }
    }
    #paso-1{
        margin-left: 15px;
    }
    #paso-4{
        margin-right: 15px;
    }
    #step-4{
        .nextBtn{
            pointer-events: none;
            background: lightgray;
            color: lightgray;
            &:hover{
                cursor: not-allowed;
            }
        }
    }
}
