/* ============================================ *
 * Header - Account
 * ============================================ */

/* -------------------------------------------- *
 * Skip Link - Account
 */

.skip-account {

    &:not(.skip-active):hover {
        .icon {
            background-position: -50px+4px (-50px + ($toolbar-icon-padding-offset/2) + 2px);
        }
    }

    .icon {
        @extend .icon-sprite;
        background-position: -0px+4px (-50px + ($toolbar-icon-padding-offset/2) + 2px);
    }
}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

    .skip-account {
        float: left;
        width: 50%;
        vertical-align: top;
        display: inline-block;
        padding: 0 10px;
    }

    .skip-account:hover {
    }

    #header-account.skip-active {
        display: block;
        position: absolute;
        z-index: 200;
        top: 90px;
        right: 45%;
        width: 215px;
        height: auto;
        left: unset;
        font-weight: 300;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -4px;
    }

    #header-account a {
        display: block;
        padding: 5px 10px;
        color: $c-text;
        line-height: 2;
    }

    #header-account a:hover {
        color: $c-action;
    }

}

/* -------------------------------------------- *
 * Account Links
 */

#header-account {
    padding: 5px 0;
}

#header-account a {
    position: relative;
    display: block;
    padding: 5px 10px;
    line-height:23px;
    text-align: center;
}

#header-account a:hover {
    color: $c-action;
}

