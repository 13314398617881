/* ============================================ *
 * Catalog - Product
 * ============================================ */

 .product-view {
	$separacionElementos: 20px;
	position: relative;
	.price-box .discount-label {
		position: absolute;
		z-index: 0;
		border-radius: 100%;
		width: 70px;
		height: 70px;
		top: 10px;
		left: 1px;
		font-size: 1.5rem;
		color: white;
		background: $secondary-color;
		padding: 0;
		display: block;
		line-height: 20px;
		font-weight: 900;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		small {
			font-size: 1rem;
			line-height: normal;
			display: block;
			font-weight: 700;
		}
	}
	&.thumbnail,
	&.loaded {
		.price-box .discount-label {
			display: flex;
		}
	}
	&.thumbnail {
		.price-box .discount-label {
			@media all and (min-width: 769px) {
				left: 120px;
			}
		}
	}
	.product-shop .product-name,
	.product-img-box .product-name {
		margin-bottom: $separacionElementos - 5px;
	}
	.product-shop .product-name .h1,
	.product-img-box .product-name h1 {
		color: $c-text;
		margin-bottom: 0;
		border: 0;
		font-size: 33px;
		font-weight: 900;
		padding-bottom: 0;
	}
	.product-shop {
		width: 50%;
		float: right;
		.product-name {
			display: none;
		}
		.product-name .h1 {
			float: none;
			margin-bottom: 0;
		}
		.extra-info {
			float: none;
			clear: left;
			width: 100%;
			display: flex;
			.comments,
			.stock {
				width: 50%;
			}
		}
		.price-info {
			float: none;
			text-align: left;
			padding-top: $separacionElementos;
			border-top: 1px solid $c-module-border-light;
		}
		.extra-info,
		.price-info {
			max-width: 100%;
		}
		.short-description {
			margin-top: $separacionElementos;
			margin-bottom: $separacionElementos;
			clear: both;
			font-weight: 300;
		}
		.ratings {
			display: block;
			margin-bottom: $separacionElementos;
		}
		.extra-info.second {
			margin-bottom: $separacionElementos;
		}
		.availability {
			font-size: $f-size-m;
			font-family: $f-stack-sans;
			color: $c-gray;
			line-height: 20px;
			font-weight: 900;
			vertical-align: middle;
			text-align: right;
			@include bp(max-width, $xs-bootstrap) {
				font-size: $f-size-xxs;
			}
		}
		.price-box {
			margin-top: 0;
			margin-bottom: $separacionElementos;
			display: flex;
			&.map-info {
				display: block;
			}
			.old-price,
			.special-price,
			.regular-price {
				padding-right: 0;
				line-height: 1.1;
			}
			.special-price {
				padding-right: 1rem;
			}
			.regular-price .price,
			.special-price .price,
			.full-product-price .price {
				color: $primary-color;
				font-size: 34px;
			}
			.special-price {
				.price-label {
					font-size: $f-size-xl;
					text-transform: uppercase;
				}
				span.weee {
					color: $c-text;
					span.price {
						font-size: $f-size-xl;
					}
				}
			}
			.price-excluding-tax,
			.price-including-tax {
				display: block;
				line-height: 1.2;
				color: $c-text;
				.label {
					font-size: $f-size-xl;
					font-weight: 300;
				}
				.price {
					font-size: 20px;
				}
			}
		}
	}
	.price-box.map-info a {
		display: inherit;
	}
	.old-price {
		.price-label {
			display: block;
			font-family: $f-stack-sans;
			font-weight: 100;
		}
		.price {
			font-weight: 400;
		}
	}
	.add-to-cart-wrapper,
	.block-related {
		width: 50%;
		float: right;
		clear: right;
	}
}

@include bp(max-width, 850px) {
	.product-view {
		.product-shop {
			.price-box {
				.price-excluding-tax,
				.price-including-tax {
					.label {
						font-size: $f-size-l;
					}
					.price {
						font-size: $f-size-xxl;
					}
				}
			}
		}
	}
}

@include bp(max-width, $bp-medium) {
	.product-view {
		.add-to-cart-wrapper {
			float: none;
			width: 100%;
			clear: both;
		}
		.product-shop {
			.price-box {
				.price-excluding-tax,
				.price-including-tax {
					.label {
						font-size: $f-size-xl;
					}
					.price {
						font-size: 20px;
					}
				}
			}
		}
	}
}

@include bp(max-width, 420px) {
	.product-view {
		.product-shop {
			.price-box {
				.price-excluding-tax,
				.price-including-tax {
					margin-bottom: 5px;
					span {
						display: block;
					}
				}
			}
		}
	}
}

.product-img-box {
	width: 50%;
	float: left;
	@include bp(max-width, $xs-bootstrap) {
		width: 100%;
		float: none;
		display: flex;
		flex-direction: column;
	}
	.product-name {
		h1 {
			border: 0;
		}
	}
	.more-views {
		width: 100px;
		@include bp(max-width, $xs-bootstrap) {
			width: 100%;
			float: none;
			order: 3;
		}
		@include bp(min-width, $xs-bootstrap+1){
			margin-right: 20px;
		}
	}
	.product-image {
		margin-bottom: $box-spacing;
		@include bp(max-width, $xs-bootstrap) {
			width: 100%;
			float: none;
		}
	}
	.product-image img {
		max-width: 100%;
		// Since product detail page images are not cropped to a specific aspect ratio, in order to make the image not
		// cut off on tall skinny screens, we are setting a max-height on the image
		max-height: 750px;
		// Centering the image for instances where max-height kicks in
		margin: 0px auto;
	}
}

// On smaller devices, we want to further limit the height of the image, to prevent cropping
@include bp(max-width, $bp-xsmall) {
	.product-img-box .product-image img {
		max-height: 450px;
	}
}

.product-image-gallery {
	position: relative;
	.gallery-image {
		display: none;
		&.visible {
			display: block;
			&.hidden {
				visibility: hidden;
			}
		}
	}
	@include loadingOverlay();
}

.product-image-thumbs {
	@include bp(max-width, $xs-bootstrap) {
		text-align: center;
	}
	li {
		display: inline-block;
		border: 1px solid $c-module-border-light;
		margin-bottom: 13px;
		&:hover,
		&.active {
			border-color: $c-action;
		}
	}
	a {
		display: block;
		border: 1px solid transparent;
		outline: none;
		float: left;
	}
}

.product-view .product-shop,
.product-view .add-to-cart-wrapper,
.product-view .block-related {
	padding-left: 10px;
}

.product-img-box {
	padding-right: 10px;
}

.product-img-box,
.product-collateral,
.product-view .block-related,
.box-collateral {
	margin-bottom: $box-spacing;
}

.product-view {
	position: relative;
	.add-to-box:after,
	.add-to-cart:after {
		@include clearfix;
	}
	.add-to-cart {
		margin-bottom: 0;
		margin-top: 0;
		padding-top: 25px;
		padding-bottom: 20px;
		border-top: 1px solid $c-module-border-light;
	}
	/* Set a min-height so that the floated elements break appropriately */
	.add-to-cart .qty-wrapper,
	.product-options-bottom .price-box,
	.add-to-cart-buttons {
		min-height: 40px;
	}
	.product-options-bottom .price-box {
		display: none
	}
	.product-options-bottom .price-box,
	.add-to-cart .qty-wrapper,
	.add-to-cart-buttons .button,
	.add-to-cart-buttons .paypal-logo {
		margin-bottom: 10px;
	}
	.add-to-cart .qty-wrapper,
	.add-to-cart .qty-wrapper label {
		margin-right: 7px;
		float: left;
	}
	.add-to-cart .qty-wrapper label {
		line-height: 40px;
	}
	.add-to-cart .qty-wrapper .qty {
		float: left;
		height: 40px;
	}
	.add-to-cart-buttons {
		float: left;
		margin-right: 10px;
		max-width: 100%;
		@include bp(max-width, $xs-bootstrap) {
			clear: both;
			margin: 0;
			float: none;
			display: block;
			width: 100%;
		}
		.paypal-logo {
			clear: left;
			text-align: center;
			.paypal-button {
				margin: 0 auto;
				display: block;
				width: 170px;
			}
			.bml_button {
				display: block;
				width: 174px;
				margin: 0 auto;
				a {
					text-align: center;
				}
			}
			.paypal-or {
				display: block;
				margin-bottom: 10px;
				margin-left: 0;
				margin-right: 0;
				line-height: 1;
				text-align: center;
			}
		}
	}
	.add-to-cart-buttons .button {
		@include buttonXL;
		min-height: 50px;
		line-height: 50px;
		font-size: 15px;
		font-size: 14px;
    	text-transform: uppercase;
		font-weight: 700;
		border-radius: 5px;
	}
	.alert-price,
	.alert-stock {
		clear: both;
	}
	.qty-container {
		float: left;
		margin-right: 20px;
		@include bp(max-width, $xs-bootstrap) {
			clear: both;
			margin: 0;
			float: none;
			display: block;
			width: 100%;
			margin-bottom: 20px;
		}
		.qty-wrap {
			display: flex;
			align-items: center;
			@include bp(max-width, $xs-bootstrap) {
				max-width: 60%;
				margin: 0 auto;
			}
			#qty {
				display: block;
				border: none;
				padding: 13px 5px;
				min-width: 30px;
				height: 50px;
				max-width: 50px;
				min-width: 50px;
				border: 1px solid $c-module-border;
				outline: none;
				border-radius: 0px;
				@include bp(max-width, $xs-bootstrap) {
					-ms-flex: 0 0 33.333333%;
					flex: 0 0 33.333333%;
					max-width: 33.333333%;
					display: block;
					width: 100%;
					padding: 13px 5px;
					height: 52px;
				}
			}
			.qty-elements {
				display: block;
				font-size: 23px;
				padding: 0;
				margin: 0;
				height: auto;
				@include bp(max-width, $xs-bootstrap) {
					-ms-flex: 0 0 33.333333%;
					flex: 0 0 33.333333%;
					max-width: 33.333333%;
				}
				a {
					padding: 10px 20px;
					border: 1px solid $c-module-border;
					color: $c-text;
					text-decoration: none;
					outline: none;
					font-weight: 300;
					@include bp(max-width, $xs-bootstrap) {
						padding: 8px 10px;
						display: block;
						text-align: center;
						width: 100%;
					}
				}
				&.minus {
					a {
						border-right: 0;
					}
				}
				&.plus {
					a {
						border-left: 0;
					}
				}
			}
		}
	}
}

@include bp(max-width, $bp-xsmall) {
	.product-view .add-to-cart-buttons {
		.paypal-or {
			line-height: 20px;
		}
		.paypal-logo,
		.paypal-or,
		.paypal-button,
		.button {
			text-align: center;
			width: 100%;
			display: block;
			margin-right: 0;
			margin-left: 0;
			float: none;
		}
	}
}


/* This is the "or" that separates the "Add to Cart" and "Add to X" links */

.add-to-box .or {
	display: none;
}

.product-view .add-to-links {
	clear: both;
	margin-top: 8px;
	margin-right: 15px;
	float: left;
	&:after {
		@include clearfix;
	}
	li {
		float: left;
	}
	a {
		padding: 7px 10px 7px 0px;
		margin-left: 7px;
		border-right: 1px solid $c-module-border-light;
	}
	li:last-child a {
		border-right: 0px;
		display: none;
	}
}

.product-view .sharing-links {
	float: left;
	margin-top: 10px;
	&:after {
		@include clearfix;
	}
	li {
		float: left;
		padding: 0px 7px 7px;
	}
	a {
		display: block;
		width: 30px;
		height: 30px;
		color: white;
		background: $primary-color;
		border-radius: 50%;
		text-align: center;
		&:hover {
			background: $secondary-color;
		}
		i{
			padding-top: 8px;
		}
	}
}

@include bp(max-width, 599px) {
	.add-to-box .add-to-links {
		a {
			float: none;
			width: auto;
			@include image-replacement-override;
		}
	}
}

.product-collateral {
	clear: both;
	.tab-container{
		.tab-content{
			font-weight: 300;
		}
	}
}

.collateral-tabs {
	dd {
		h2 {
			display: none;
		}
	}
}

.box-collateral {
	clear: both;
}

@include bp(min-width, $bp-medium + 1) {
	.product-collateral {
		@include tabs;
	}
}

@include bp(max-width, $bp-medium) {
	.product-collateral {
		@include accordion;
		@include accordionCollapse;
	}
}

@include bp(max-width, $bp-medium) {
	.product-view .product-shop,
	.product-view .add-to-cart-wrapper,
	.product-view .block-related,
	.product-img-box,
	.product-collateral {
		width: 100%;
		float: none;
		padding-right: 0;
		padding-left: 0;
	}
}

@include bp(min-width, $bp-medium + 1) {
	.product-view .product-shop .product-name {
		display: block;
	}
	.product-img-box .product-name {
		display: none;
	}
}

@include bp(max-width, $bp-xsmall) {
	.product-view .product-shop,
	.product-img-box,
	.product-collateral,
	.product-view .block-related,
	.box-collateral {
		margin-bottom: $trim-small;
	}
}

.product-view .ratings {
	.rating-links {
		font-size: 0.825rem;
		margin-top: 0;
		display: inline-block;
		margin-bottom: 0;
		.countReviews {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
}

#product-attribute-specs-table {
	max-width: $max-std-formatted-width;
	border: 1px solid $c-table-border;
	th {
		border-right: 1px solid $c-table-border;
		border-bottom: 1px solid $c-table-border;
	}
}


/* -------------------------------------------- *
 * Catalog - Grouped Product List
 */

.grouped-items-table-wrapper {
	border: solid 1px $c-table-border;
	width: 100%;
	padding: $box-spacing;
	margin-bottom: 15px;
	tbody tr:last-child td {
		border-bottom: none;
	}
	@include bp(max-width, $bp-xsmall) {
		margin-bottom: $trim-small;
	}
}

.grouped-items-table {
	.name-wrapper {
		color: $c-text-primary;
		font-family: $f-stack-special;
	}
	.qty-wrapper {
		padding-top: $element-spacing;
	}
	.qty-label {
		margin-left: $element-spacing;
	}
	td {
		padding: 4px;
		&:last-child {
			width: 30%;
			text-align: left;
		}
	}
	.price-box {
		text-align: right;
	}
}


/* -------------------------------------------- *
 * Catalog - Product Options
 */

.product-options {
	width: 100%;
	margin: 10px 0 0;
	padding: 0;
	clear: both;
	position: relative;
}

.product-options p.required {
	position: static;
	top: auto;
	left: auto;
	text-align: left;
	margin-bottom: 10px;
}

@include bp(max-width, $bp-large) {
	.product-view .product-options .required {
		position: static;
		margin-top: 0;
		margin-bottom: 5px;
	}
}

.product-options dt {
	margin: $gap 0 3px;
	font-weight: normal;
}

.product-options dt:first-child {
	margin-top: 0;
}

.product-options dd .qty-holder {
	display: block;
	padding: $gap 0 0 0;
}

.product-options dd .qty-holder .qty {
	width: 3em;
}

.product-options dd .qty-holder label {
	vertical-align: middle;
}

.product-options dd .qty-disabled {
	background: none;
	border: 0;
	padding: 0 2px;
}

.product-options dd {
	padding: 0 0 10px 0;
	margin: 0 0 5px;
}

.product-options dl.last dd.last {
	border-bottom: 0;
	padding-bottom: 5px;
	margin-bottom: 0;
}

.product-options dd .input-text {
	width: 98%;
}

.product-options dd .input-box {
	padding-top: 0;
}

.product-options dd input.datetime-picker {
	width: 150px;
}

.product-options dd .time-picker {
	@include inline-block;
	padding: 2px 0;
	vertical-align: middle;
}

.product-options dd textarea {
	width: 98%;
	height: 8em;
}

.product-options dd select {
	width: 330px;
	max-width: 100%;
}

.product-options ul.options-list {
	margin-right: 5px;
}

.product-options ul.options-list li {
	line-height: 1.5;
	margin-bottom: 7px;
}

.product-options ul.options-list li:last-child {
	margin-bottom: 0;
}

.product-options ul.options-list .radio,
.product-options ul.options-list .checkbox {
	float: left;
	margin-top: 5px;
}

.product-options ul.options-list .label {
	display: block;
}

.product-options ul.options-list label {
	font-weight: normal;
}

.product-options p.note {
	margin: 0;
	font-size: $f-size-xxs;
}

.product-options-bottom {
	padding: 0;
	border-top: 0;
	margin-bottom: $box-spacing;
}

.product-options-bottom:after {
	@include clearfix;
}

.product-options-bottom .product-pricing,
.product-options-bottom .tier-prices {
	float: right;
	margin: 0;
	padding: 0 0 $gap;
	border: 0;
	background: 0;
	color: $c-text;
	@include bp(max-width, $bp-xlarge) {
		float: none;
		width: 100%;
		text-align: right;
		padding-bottom: $gap / 2;
		margin-bottom: $gap;
	}
}

.product-options-bottom .tier-prices li {
	background: 0;
	padding: 2px 0;
}

.product-options-bottom .tier-prices .price,
.product-options-bottom .tier-prices .benefit {
	color: $c-text;
}


/* "display: none" should be removed if products on site have a long list of product options and those product options */


/*  affect the price so that the customer can see the updated price close to the "Add to Cart" button */

.product-options-bottom .price-box {
	display: none;
	float: right;
	margin: 0 0 $gap;
	padding: 0;
}

@include bp(max-width, $bp-xsmall) {
	.product-options-bottom .price-box .price-as-configured {
		text-align: right;
		.price-label {
			display: block;
		}
	}
}

.product-options-bottom .price-tax {
	float: left;
}


/* -------------------------------------------- *
 * Related Products
 */


/* Block: Related */

.block-related {
	li {
		margin-bottom: $element-spacing;
	}
	.block-title {
		border-bottom: none;
		h2 {
			@include h2();
			margin-bottom: 0;
		}
	}
	.block-subtitle {
		margin-bottom: $element-spacing;
	}
	.product {
		margin-left: 20px;
		position: relative;
	}
	.checkbox {
		float: left;
		margin-top: 36px;
	}
	.product-details {
		margin-left: 90px;
	}
	.product-name {
		margin-bottom: 3px;
	}
	.product-details .price-box {
		margin: 2px 0 3px;
		a,
		p,
		span {
			float: left;
			margin-left: 3px;
			&:first-child {
				margin-left: 0;
				font-size: $f-size-xl;
				padding-right: 5px;
				padding-left: 5px;
			}
		}
		&:after {
			@include clearfix;
		}
	}
	.link-wishlist {
		display: none;
	}
}


/* -------------------------------------------- *
 * Upsell Products
 */

.box-up-sell {
	margin-top: 40px;
    margin-bottom: 30px;
	.ratings {
		display: none;
	}
}

.link-wishlist{
	&:focus{
		outline: none;
	}
}



.icon-wishlist-product {
	cursor: pointer;
	width: 25px;
    height: 21px;
	opacity: .5;
	background-image: url(../images/wishlist-icon-default.svg);
	transition: all .25s;
	background-repeat: no-repeat;
	&:hover {
	  opacity: 1;
	}
	&:focus{
		outline: none;
	}
	&.in-wishlist {
	  opacity: 1;
	  animation-name: wishlist-ani;
	  animation-duration: 1000ms;
	  background-image: url(../images/wishlist-icon-inlist.svg);
	}
}
  
@keyframes wishlist-ani {
	  0%   {transform: scale(1);}
	  25%  {transform: scale(.8);}
	  50%  {transform: scale(1.2);}
	  100% {transform: scale(1);}
}

.product-view{
	.owl-carousel{
		.actions{
			min-height: auto;
		}
		.add-to-links{
			display: none;
		}
		.price-box{
			.discount-label{
				left: 0;
				top: 0; 
			}
		}
	}
}

@include bp(max-width, $xs-bootstrap){
	.add-to-cart{
		position: fixed;
		bottom: -1px;
		left: -1px;
		width: calc(100% + 1px);
		background: white;
		padding: 15px !important;
		z-index: 99;
		box-shadow: rgba(0, 0, 0, 0.1) 0px -3px 26px;
	}
	.product-view{
		.qty-container{
			width: 40%;
			display: inline-block;
			margin-bottom: 0px;
			.qty-wrap{
				width: 100%;
				max-width: 100%;
			}
		}
		.add-to-cart-buttons{
			width: 59%;
			display: inline-block;
			float: right;
			padding-left: 10px;
			.button{
				height: 52px;
				max-height: 52px;
				min-height: 52px;
				font-size: 12px;
				padding: 0 15px;
			}
		}
	}
}

.product-collateral{
	@include bp(max-width, $xs-bootstrap){
		margin-top: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid lightgray;
		.collateral-tabs{
			margin-bottom: 0;
			margin-top: 10px;
			.tab-container{
				padding: 10px 10px 30px;
			}
		}
		.tab{
			padding: 15px 5px;
			border-top: 1px solid lightgray;
			margin: 0;
		}
	}
}

.product-view{
	.price-box{
		.discount-label{
			@include bp(max-width, $xs-bootstrap){
				top: 55px;
			}
		}
	}
}


.catalog-product-view{
	footer{
		@include bp(max-width, $xs-bootstrap){
			margin-bottom: 80px;
		}
	}
}


.catalog-category-view{
	.block-layered-nav{
		@include bp(min-width, $bp-medium +1){
			padding: 0 0 10px 0;	
		}
		dd{
			width: 100%;
		}
		.price-row-container{
			input{
				width: 100%;
			}
		}
	}
}

.products-grid{
    .carousel-sku {
        font-weight: 300;
        color: gray;
        font-size: 12px;
	}
	.availability.out-of-stock{
		text-transform: unset;
		display: inline-block;
		font-size: 14px;
		color: white;
		font-weight: 700;
		width: 100%;
		padding: 10px 0;
		background: gray;
		border-radius: 5px;
		cursor: not-allowed;
		&:before{
			display: none;
		}
		@include bp(min-width, $bp-medium+1){
			width: auto;
    		padding: 7px 35px;
		}
	}
}

.catalog-category-view{
    .main-container{
		background: white;
    }
}

.product-view{
	.product-img-box{
		display: flex;
		flex-wrap: wrap;
		@include bp(min-width, $xs-bootstrap+1){
			flex-wrap: nowrap;
		}
	}
	.products-grid{
		.old-price .price-label{
			display: none;
		}
	}
}


