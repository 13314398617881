@charset "UTF-8";

/* ============================================ *
 * Base Styles
 * ============================================ */

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

// Prevent 300ms delay in IE on touch devices
a,
button {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

body,
button,
input,
select,
table,
textarea {
    font-family: $f-stack-sans;
    color: $c-text;
    font-size: $f-size;
    line-height: $b-line-height;
}

*::selection {
    background: $primary-color;
    color: white;
}

a {
    color: $c-link;
    text-decoration: none;
}

a:hover {
    color: $c-link-hover;
    text-decoration: underline;
}

a:focus {
    outline-color: $c-link-active;
    color: darken($c-action, 30%);
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tp-caption a {
    color: inherit;
    text-decoration: inherit;
    font-size: inherit;
}

/* ============================================ *
 * Typography
 * ============================================ */

h1,
.h1 {
    @include h1;
}

h2,
.h2 {
    @include h2;
}

h3,
.h3 {
    @include h3;
}

h4,
.h4 {
    @include h4;
}

h5,
.h5 {
    @include h5;
}

h6,
.h6 {
    @include h6;
}

.widget-title {
    text-align: center;
}

.cms-home .widget-title,
.cms-home .block-title {
    display: block;
    width: 100%;
}

.title-section,
.cms-home .block-title h2,
.cms-home .widget-title h2 {
    text-align: center;
    font-size: 2.3125rem;
    line-height: 3rem;
    font-weight: 500;
    color: $c-gray;
    text-align: center;
    border: 1px solid $c-title-border;
    padding: 10px 15px;
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    text-transform: none;
}

/* ============================================ *
 * Shared Classes
 * ============================================ */

.address-list address {
    margin-bottom: 1em;
}

.availability {
    margin-bottom: 0;
    font-size: $f-size-s;
}

.availability.in-stock {
    color: $c-text;

    &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f058";
        font-size: 25px;
        color: $c-green;
        position: relative;
        top: 3px;
        margin-right: 5px;
    }
}

.availability.available-soon,
.availability.out-of-stock {
    color: $c-text;

    &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f057";
        font-size: 25px;
        color: $c-red;
        position: relative;
        top: 3px;
        margin-right: 5px;
    }
}

.availability-only {
    color: $c-danger;
    margin-bottom: $box-spacing;
}

/* -------------------------------------------- *
 * Page Titles
 */

.page-title h1,
.page-title h2,
.product-name h1,
.product-name .h1 {
    @include pageTitle;
}

.catalog-category-view {

    .page-title h1,
    .page-title h2,
    .product-name h1,
    .product-name .h1 {
        font-weight: 100;
        font-size: 3rem;
        line-height: 3.2rem;

        @include bp(max-width, $xs-bootstrap) {
            font-size: 36px;
            line-height: 45px;
        }
    }
}
.catalog-category-view {
    .page-title h1{
        font-weight: 800;
        font-size: 2rem;
        line-height: 3.2rem;
        margin-bottom: 5px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

/* -------------------------------------------- *
 * Block Module
 */

.block,
.col-left-first {
    margin-bottom: 20px;
}

.col-left-first .block:last-of-type {
    margin-bottom: 0;
}

.footer {
    .block-title {
        display: block;
        width: 100%;
        position: relative;
        padding: 0 15px;

        h2,
        h3,
        strong {
            @include h4;
            font-size: 1.125rem;
            line-height: 1.8125rem;
            margin-bottom: 2rem;
            color: white;
            text-transform: none;
            font-weight: 900;
        }

        small {
            font-size: 100%;
            font-weight: normal;
            color: $c-text-gray;
        }
    }
}

// On account pages, don't remove the top border in order to create separation between block and .block-account
body:not(.customer-account) .block:first-child .block-title {
    border-top: none;
    padding-top: 0;
}

.block-subtitle {
    font-weight: bold;
    margin-bottom: $element-spacing;
}

.block-content {
    margin-top: 5px;
}

.block-content.unpad {
    padding: 0;
}

.block-content li.item {
    margin: 0 0 $box-spacing 9px;
}

.block-content li.item:last-child {
    margin-bottom: 0;
}

.block .actions {
    margin: $box-spacing 0 0;

    &:after {
        @include clearfix;
    }

    a {
        float: left;
    }

    .button {
        float: right;
    }
}

.col-left,
.col-right {

    // Align the vertical height of the anchor to line up with the button
    .block .actions .button~a {
        line-height: 33px;
        margin-right: 5px;
    }
}

@include bp(max-width, $bp-medium) {

    .sidebar {

        // Don't apply to layered nav, since it gets special treatment
        .block:not(.block-layered-nav) {
            margin-bottom: 0;

            .block-title {
                padding: 0;
                margin-top: 0;
                border-bottom: none;
                border-top: none;
                margin-bottom: 0;

                >strong {
                    @include accordionDtBase('right', $c-module-border);
                    display: block;
                    width: 100%;
                    cursor: pointer;
                    border-bottom: 0;
                    padding-left: 25px;
                }

                &.active {
                    >strong {
                        @include accordionDtBase('down', $c-module-border);
                        padding-left: 25px;
                    }
                }
            }

            .block-content {
                padding: $box-spacing;
                margin-top: 0;
                border-width: 0 1px;
                border-style: solid;
                border-color: $c-module-border;
            }
        }

        // This selector is highly fickle, because if a div gets added beneath the last normal block in a sidebar,
        // it will break. However it is the simplest solution to applying this trivial style.
        .block:last-of-type {
            border-bottom: 1px solid $c-module-border;
        }
    }
}

/* -------------------------------------------- *
 * Secondary Navigation
 */
.customer-account {
    .block-account li {
        text-transform: none;
    }
    .block-title {
        font-size: 1.3rem;
    }
}

.block-account,
.block-cms-menu {
    .block-title {
        padding-top: 0;
        border-top: none;
    }

    li {
        text-transform: uppercase;
        font-family: $f-stack-special;
        margin: 7px 0;

        strong {
            font-weight: 400;
            color: $c-action;
        }

        a {
            color: $c-text;

            &:hover {
                color: $c-action;
            }
        }
    }
}
.sidebar .block-content {
    h2.product-name,
    h3.product-name,
    h4.product-name,
    h5.product-name,
    p.product-name {
        font-size: 0.8rem;
        line-height: 1rem;
        text-transform: none;
    }
    .actions {
        button {
            background: $primaryGradient;
            color: white;

            &:hover {
                background: $c-red;
                cursor: pointer;
            }

            &:active {
                background: $c-red;
                color: white;
            }

            &:focus {
                background: $c-red;
                color: white;
            }
        }
    }
}
/* ============================================ *
 * Buttons
 * ============================================ */

/* Secondary Buttons */
.cart .buttons-set .button,
.cart-table .button,
.sidebar .actions .button,
.button.button-secondary {
    background: $c-button-secondary;
    color: $c-text;
    padding: 7px 15px;

    &:hover {
        background: $c-button-secondary-hover;
        cursor: pointer;
    }

    &:active {
        background: $c-button-secondary-active;
        color: $c-text;
    }

    &:focus {
        color: $c-text;
        background: $c-button-secondary-active;
        outline: none;
    }
}

.sidebar .actions button.button {
    white-space: normal;
}

/* Primary Buttons */

.button,
.cart-table .product-cart-actions .button,
#co-shipping-method-form .buttons-set .button,
.footer .button {
    background: $c-button;
    display: inline-block;
    padding: 7px 20px;
    border: 0;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    font-family: $f-stack-special;
    line-height: 19px;
    text-align: center;
    text-transform: unset;
    vertical-align: top;
    white-space: nowrap;
    transition: all .3s;
    &:hover {
        background: $c-button-hover;
        cursor: pointer;
    }
    &:focus {
        outline: none;
        color: #FFFFFF;
    }
}

a.button {
    text-decoration: none;
}

a.button:hover {
    color: #FFFFFF;
}

/* Disabled - class for anchor, state for form elements */
.button.disabled,
.button:disabled {
    background: #C8C8C8;
    opacity: 0.6;
    cursor: not-allowed;
}

/* Adjacent buttons */
.button+.button {
    margin-left: 5px;
}

// This mixin gets applied to elements that are only buttons on smartphones (or sometimes tablets), such as layered
// nav links or checkbox labels.
// Building this as a mixin (rather than an extendable class) since it will be included within mq breakpoints, and
// extending doesn't work within media queries: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#_in_directives
@mixin touch-button {
    color: $c-text;
    background-color: $c-module-background;
    padding: 5px 10px;
}

.button2 {
    border: 0;
    padding: 0 5px;
    margin: 0;
    background: transparent;
    cursor: pointer;
    vertical-align: middle;
    background: $primaryGradient;
    border-radius: 5px;
}

.button2:focus {
    outline: none;
}

.button2 span,
.button2 span span {
    line-height: 30px;
    height: 30px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 600;
    font-family: $f-stack-special;
    color: white;
    font-size: 14px;
    padding: 0 5px;

    &:hover {
        text-decoration: none;
        color: white;
    }
}

@include bp(max-width, $bp-medium) {
    .col2-set .buttons-set {

        .button,
        .button2 {
            float: none;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .back-link {
            display: none;
        }

        .required {
            display: none;
        }
    }
}

@include bp(max-width, $bp-xsmall) {
    .buttons-set {
        .button {
            float: none;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: $element-spacing;
        }

        .back-link {
            display: none;
        }

        .required {
            display: none;
        }
    }
}

/* -------------------------------------------- *
 * Paypal Button
 */

.paypal-logo.paypal-after {
    float: left;
}

.paypal-after .paypal-or {
    float: left;
}

.paypal-or {
    line-height: 34px;
    margin: 0px 10px 5px;
}

.paypal-after .paypal-button {
    float: left;
}

.paypal-button {
    line-height: 0px;
}

.paypal-button img {
    display: inline;
}

@include bp(max-width, 740px) {
    .paypal-or {
        line-height: 20px;
    }

    .paypal-logo,
    .paypal-or,
    .paypal-button {
        text-align: center;
        width: 100%;
        display: block;
        margin-right: 0;
        margin-left: 0;
        float: none;
    }
}

/* -------------------------------------------- *
 * Button Sets
 */

.buttons-set {
    clear: both;
    margin: $box-spacing 0 0;
    padding-top: $box-spacing;
    border-top: 1px solid $c-module-border-light;
    text-align: right;

    p.required {
        margin: 0;
        margin-left: $gap;
        line-height: 33px;
        float: right;
    }

    .back-link {
        float: left;
        margin: 0;
        line-height: 33px;
    }

    a:not(.button) {
        line-height: 20px;
        display: inline-block;
        padding: 5px;
    }

    button.button {
        float: right;
        margin-left: 5px;
        min-width: 140px;
    }

    &:after {
        @include clearfix;
    }
}

/* -------------------------------------------- *
 * Icons
 */

.icon-sprite {
    background-image: url(../images/icon_sprite.png);
    background-repeat: no-repeat;
    @include image-replacement;
}

@include if-min-resolution(2) {
    .icon-sprite {
        background-image: url(../images/icon_sprite@2x.png);
        background-size: 100px 1000px;
    }
}

/* -------------------------------------------- *
 * Breadcrumbs
 */

.breadcrumbs {
    overflow: hidden;
    margin: 0 0 15px;
}

.breadcrumbs li {
    float: left;
    font-size: $f-size-xs;
    font-family: $f-stack-special;
    text-transform: none;
}

.breadcrumbs a {
    font-family: $f-stack-special;
    font-size: 0.925rem;
    float: left;
    color: gray;
}

.breadcrumbs a:hover {
    color: $c-action;
}

.breadcrumbs strong {
    color: $c-text;
    font-family: $f-stack-special;
    font-size: 0.925rem;
    font-weight: bold;
}

.breadcrumbs span {
    float: left;
    padding: 0 7px;
}

/* -------------------------------------------- *
 * Button - Remove / Previous
 */

.btn-remove,
.btn-previous {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $c-action;
    text-align: center;
    /* Hide text */
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    position: relative;

    &:hover {
        background-color: $c-action;
        border-color: $c-action;
    }
}

.btn-remove {
    &:after {
        content: 'X';
        color: $c-action;
        height: 20px;
        line-height: 20px;
        width: 100%;
        font-size: 10px;
        font-family: Arial, $f-stack-sans;
        font-weight: bold;
    }

    &:hover:after {
        color: #FFFFFF;
        text-decoration: none;
    }
}

.btn-remove2 {
    @extend .icon-sprite;
    $icon-padding: 3px;
    background-position: 4px -650px+2px;
    border: none;
    vertical-align: top; // Adding this so that the button will show at the top of the cart rows, rather than at the baseline

    &:after {
        display: none;
    }

    &:hover {
        background-color: transparent;
        @include opacity(0.8);
    }
}

.btn-previous {
    &:after {
        @include triangle(left, 4px, $c-action);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -2px;
        margin-top: -4px;
    }

    &:hover:after {
        @include triangle(left, 4px, #FFFFFF);
    }
}

.block-layered-nav .currently,
.mini-products-list,
#compare-items {

    .btn-remove,
    .btn-previous {
        float: right;
        margin-left: 6px;
    }
}

/* -------------------------------------------- *
 * Checkout Agreements
 */

.checkout-agreements li {
    margin-bottom: 20px;
}

.checkout-agreements .agreement-content {
    overflow-y: auto;
    max-width: 670px;
    max-height: 125px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid $c-module-border;
    background: $c-module-background;
}

/* -------------------------------------------- *
 * CVV Security Code
 */

.cvv-what-is-this {
    margin-left: 10px;
    font-size: $f-size-s;
    line-height: 24px;
}

/* -------------------------------------------- *
 * Container
 */
.fullwidth .main-container,
.fullwidth .footer-container {
    max-width: 100%;
}

.main-container,
.footer-container {
    position: relative;
    margin: 0 auto;
    max-width: $max-container-width;
    padding: $trim-small ($trim-small - $trim/2);

    &:after {
        @include clearfix;
    }
}

.footer-container {
    padding: 50px ($trim-small - $trim/2) 30px !important;

    @include bp(max-width, $xs-bootstrap) {
        padding: 10px 0 0 !important;
    }
}

@include bp(min-width, 480px) {

    .main-container,
    .footer-container {
        padding: $trim;
    }
}

footer#footer {
    background: $primary-color-dark;
}

footer .suscribe-container {
    background: $gradient-bg;
    padding: 18px 0;

    @include bp(min-width, $xs-bootstrap+1) {
        padding: 18px 0;
    }
}

.postFooter {
    background: $primary-color-dark;
    padding: 10px 15px 15px;
    color: white;
    @include bp(min-width, $xs-bootstrap+1){
        padding: 15px 0;
    }
}

/* -------------------------------------------- *
 * Column Layouts
 */

.main:after,
.col-wrapper:after {
    @include clearfix;
}

.col-left,
.col-right,
.col-main {
    padding: 0 10px;
}

.col-left {
    float: left;
    width: 260px;
    padding-left: 0;
    /* Remove the 15px padding */
    clear: left;

    img {
        max-width: 100%;
    }
}
.block-list.block-viewed {
    display: none;
}
.col-right {
    float: right;
    width: percentage(240px/960px);
    padding-right: 0;
    /* Remove the 15px padding */

    img {
        max-width: 100%;
    }
}

.col-main {
    float: left;
    width: calc(100% - 260px);
}

.col1-layout {
    .col-main {
        float: none;
        width: auto;
        padding: 0;
        /* Remove the 15px padding */
    }
}

.col2-left-layout {
    .col-main {
        float: right;
        padding-right: 0;
        /* Remove the 15px padding */
    }
}

.col2-right-layout {
    .col-main {
        padding-left: 0;
        /* Remove the 15px padding */
    }
}

.col3-layout {
    .col-right {
        width: percentage(200px/960px);
    }

    .col-wrapper {
        float: left;
        width: percentage(760px/960px);

        .col-main {
            float: right;
            width: percentage(560px/760px);
        }

        .col-left {
            width: percentage(200px/760px);
        }
    }
}

@include bp(max-width, 1000px) {
    .col3-layout {
        .col-right {
            float: left;
            clear: left;
            padding-left: 0;
            padding-right: 10px;
            width: percentage(240px/960px);
        }

        .col-wrapper {
            float: right;
            width: 100%;

            .col-main {
                float: right;
                width: percentage(720px/960px);
            }

            .col-left {
                width: percentage(240px/960px);
            }
        }
    }
}

@include bp(max-width, $bp-medium) {

    .col-left,
    .col-right,
    .col-main,
    .col1-layout .col-left,
    .col1-layout .col-right,
    .col1-layout .col-main,
    .col2-left-layout .col-left,
    .col2-left-layout .col-right,
    .col2-left-layout .col-main,
    .col2-right-layout .col-left,
    .col2-right-layout .col-right,
    .col2-right-layout .col-main,
    .col3-layout .col-wrapper .col-left,
    .col3-layout .col-right,
    .col3-layout .col-wrapper .col-main {
        padding: 0;
        margin-bottom: 10px; // Since columns are stacked, we want there to be vertical spacing
        float: none;
        width: auto;
    }

    .col3-layout .col-wrapper {
        float: none;
        width: auto;
    }

    .col-main {
        float: none;
        width: auto;
    }

    .col-main .col-left {
        padding: 0;
        /* On product listing pages, the left column gets moved inside col-main on small viewports */
    }
}

/* Content Columns */

.col2-set {
    width: 100%;

    .col-1,
    .col-2 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: $box-spacing;
        @include bp(min-width, $xs-bootstrap+1) {
            width: 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 20px 20px 0;
        }
    }

    .col-1 {
        float: left;
        padding-left: 0;
    }

    .col-2 {
        float: right;
        padding-right: 0;
    }

    @include bp(max-width, $bp-xsmall) {

        .col-1,
        .col-2 {
            float: none;
            width: auto;
            border: 0;
            padding-right: 0;
            padding-left: 0;
        }
    }

    .narrow {
        width: 33%;
    }

    .wide {
        width: 65%;
    }

    &:after {
        @include clearfix;
    }
}

/* -------------------------------------------- *
 * Top Container
 */
@include bp(min-width, $bp-medium + 1) {
    .top-container {
        max-width: $max-container-width;
        margin: 0 auto;
        padding: 0 $trim;
    }
}

/* -------------------------------------------- *
 * Global Site Notice
 */

.global-site-notice {
    background: $c-dark;
    color: $c-text-white;
    font-size: $f-size-xxs;

    .notice-inner {
        padding-left: 120px;
        width: 100%;
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;
        line-height: $f-size-xxs;
        min-height: 40px;
        padding-top: (40px - $f-size-xxs) / 2;
        padding-bottom: (40px - $f-size-xxs) / 2;
        background-image: url('../images/demo-logo.png');
        background-position: left;
        background-repeat: no-repeat;
    }

    p {
        margin-bottom: 0;
    }
}

/* -------------------------------------------- *
 * Promotional Message Banner
 */

.promo-msg {
    color: $c-text-primary;
    text-align: center;
    margin: $box-spacing;
    text-transform: uppercase;
    font-family: $f-stack-special;
}


/* -------------------------------------------- *
 * Messages
 */
.success {
    color: $c-success;
}

.error {
    color: $c-danger;
    font-weight: bold;
}

.notice {
    color: $c-warn;
    font-weight: bold;
}

/* -------------------------------------------- *
 * Messages
 */

.messages {
    margin-bottom: $gap;
}

.messages li li {
    position: relative;
    margin-bottom: 5px;
    padding: 7px 10px 7px 20px;
    background: $c-module-background;
    font-size: $f-size-l;
}

.messages li li:before {
    top: 50%;
    left: 0;
    margin-top: -6px;
}

.messages .error-msg li {
    color: $c-black;
    border-left: 5px solid $c-danger;
    background-color: $c-danger-background;
}

.messages .error-msg li:before {
    @include triangle(right, 6px, $c-danger);
}

.messages .notice-msg li {
    color: $c-black;
    border-left: 5px solid $c-warn;
    background-color: $c-warn-background;
}

.messages .notice-msg li:before {
    @include triangle(right, 6px, $c-warn);
}

.messages .success-msg li {
    color: $c-black;
    border-left: 5px solid $c-success;
    background-color: $c-success-background;
}

.messages .success-msg li:before {
    @include triangle(right, 6px, $c-success);
}

// ---------------------------------------------

@include bp(min-width, 915px) {

    .order-list-grid .col-1 {
        width: 30%;
    }

    .order-list-grid .col-2 {
        width: 50%;
        padding-right: 20px;
    }

    .order-list-grid .col-3 {
        clear: none;
        width: 20%;
        padding-top: 0;
    }

}

/* -------------------------------------------- *
 * Page Popup
 */

.page-popup {
    padding: 20px;
    background: #FFFFFF;
    height: auto;
}

.page-popup h1 {
    margin: 0 0 0.5em;
    font-size: 36px;
}

/* -------------------------------------------- *
 * Payment Methods
 */

.payment-methods {
    margin-bottom: 20px;
}

.payment-methods dt {
    padding: 5px 0;
}

.payment-methods dd {
    padding-top: 10px;
}

.payment-methods .form-list {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin: 5px 15px 15px;
    padding: 15px;
    border: 1px solid $c-module-border;
    background: $c-module-background;
}

.payment-methods .form-list:before {
    @include triangle(up, 10px, $c-module-border);
    top: -11px;
    left: 30px;
}

.payment-methods .form-list:after {
    @include triangle(up, 10px, $c-module-background);
    top: -10px;
    left: 30px;
}

.payment-methods .form-list li:last-child {
    margin-bottom: 0;
}

/* -------------------------------------------- *
 * Please Wait (Loading Indicator)
 */

.please-wait {
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
    line-height: 24px;
    height: 24px;
    white-space: nowrap;
    /* Prevent the linebreak in the HTML from causing layout issues */

    img {
        float: left;
        margin-right: 5px;
        width: 24px; // Actual image is 48px, thereby supporting hi-res screens
    }
}

/* -------------------------------------------- *
 * Price Box - Standard
 */

.price-box {
    margin: $element-spacing 0;
    font-family: $f-stack-sans;
}

.price-box p {
    margin-bottom: 0;
}

.price-notice {
    color: $c-text-gray;
}

.price-box {

    .special-price,
    .regular-price {

        .price {
            color: $primary-color;
            font-size: 1.5rem;
            font-weight: 900;
        }
    }

    .old-price {
        display: block;

        .price {
            color: $secondary-color;
            font-size: 1rem;
            font-weight: 400;
            line-height: 16px;
        }
    }
}


.price-box .price,
.price {
    font-family: $f-stack-sans;
}

.price-box .price-label {
    color: $c-text-gray;
    white-space: nowrap;
    font-family: $f-stack-special;
}

.price-box .minimal-price-link {
    padding-left: 1em;
    color: $c-text-primary;
    display: block;
    /* We want this to show on its own line, otherwise the layout looks funky */

    .label {
        color: $c-text-gray;
    }
}

/* -------------------------------------------- *
 * Price Box - Special
 */

.price-box .old-price,
.price-box .special-price {
    display: block;

    .price-label {
        display: none;
    }

    .price {
        display: inline-block;
    }
}

.price-box .old-price {
    .price {
        color: $secondary-color;
        text-decoration: line-through;
    }
}

.price-box .special-price {
    color: $c-text-primary;
    padding-left: 0;

    .price-label {
        color: #D84D3C;
    }
}

/* -------------------------------------------- *
 * Price Box - Taxes
 */

.price-box .price-excluding-tax,
.price-box .price-including-tax {
    display: block;
}

span.weee {
    display: block;
}

/* -------------------------------------------- *
 * Tier Prices
 */

.product-pricing,
.tier-prices,
.tier-prices-grouped {
    display: inline-block;
    padding: 4px 8px;
    background: #FBF4DE;
    border: 1px solid #E2D4C7;
    margin-top: $element-spacing;

    li {
        font-size: $f-size-xs;
    }

    .benefit {
        font-style: italic;
    }

    .price {
        font-weight: bold;
    }
}

/* ============================================ *
 * Item Options
 * ============================================ */

.item-options {
    font-size: $f-size;
    font-family: $f-stack-sans;

    &:after {
        @include clearfix;
    }

    dt {
        float: left;
        clear: left;
        font-weight: 600;
        padding-right: 5px;
        margin: 0;
        margin-bottom: 5px;
        font-size: 0.8rem;
        line-height: 1rem;

        &:after {
            content: ': ';
        }
    }

    dd {
        float: left;
        padding-left: 0;
        margin: 0;
        margin-bottom: 5px;
        font-size: 0.8rem;
        line-height: 1rem;
    }
}

.truncated,
.truncated a.dots {
    cursor: help;
}

.truncated a.details {
    cursor: help;
    height: 16px;
    line-height: 16px;

    &:hover {
        text-decoration: none;
    }
}

.truncated .truncated_full_value {
    position: relative;
    z-index: 300;
}

.truncated .truncated_full_value .item-options {
    display: none;
    position: absolute;
    z-index: 300;
    width: 200px;
    padding: 8px;
    border: 1px solid $c-action;
    background-color: #F6F6F6;
    top: 21px;
    left: -100px;

    &:after {
        @include triangle(up, 7px, $c-action);
        left: (100px + -3px);
        top: -7px;
    }
}

.truncated .truncated_full_value .item-options>p {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.truncated .show .item-options {
    display: block;
}

@include bp(max-width, $bp-xsmall) {

    .truncated {
        cursor: inherit;

        a.details {
            display: none;
        }

        .truncated_full_value {
            .item-options {
                display: block;
                position: static;
                z-index: 1;
                width: 100%;
                border: none;
                background-color: transparent;

                p {
                    float: none;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

}

/* -------------------------------------------- *
 * Printer Friendly Page
 */

@media print {
    .page-print {
        width: 100% !important;
        page-break-before: avoid;
        height: auto;
        .col2-set{
            width: 50%;
            float: left;
            .col-2{
                padding-left: 0;
            }
        }
        h2{
            font-size: 12px;
            font-weight: 600;
        }
        table, .col2-set{
            font-size: 12px;
        }
        table{
            page-break-inside:avoid; 
            page-break-after:always;  
        }
        .buttons-set{
            display: none;
        }
       
        table, th, td{
          border-collapse: collapse;
          width: 100%;
          text-align: left;
        }
        .product-name{
            font-size: 12px;
            text-align: left;
        }
        table{
            width: calc(100% - 20px) !important;
            display: block;
        }
        .print-head{
            display: none;
        }
    }
}

.page-print {
    width: 50%;
    margin: 20px;
    background: #FFFFFF;
}

/* -------------------------------------------- *
 * Add to links
 */

.add-to-links {
    margin: 5px 0;
    margin-bottom: 0;

    a {
        display: inline-block;
        padding: 0px 3px 3px;
        padding-bottom: 0;
        font-size: 0.825rem;
        line-height: 0.85rem;
        color: $c-action;

        @include bp(min-width, $xs-bootstrap + 1px) {
            color: white;
        }
    }
}

.product-view {
    .add-to-links {
        a {
            color: $c-action;
        }
    }
}

.add-to-links .separator {
    display: none;
}

/* -------------------------------------------- *
 * Product Image
 */

.product-image,
.no-touch .product-img-box .product-image:not(.zoom-available):hover {
    position: relative;
    display: block;
    border: none;
    @include bp(max-width, $xs-bootstrap) {
        border: none;
    }
}

@include bp (max-width, $bp-medium) {
    body .product-img-box .product-image:hover {
        border-color: $c-module-border-light;
    }
}

.no-touch .product-image:hover {
    border-color: $c-module-border-light;
}

/* -------------------------------------------- *
 * Ratings
 */
.products-grid,
.products-list {
    .ratings {
        display: none;
    }
}

.product-view {

    .extra-info,
    .ratings-table {
        .rating-box {
            position: relative;
            vertical-align: top;
            font-size: 1rem;
            display: inline-block;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: $c-title-border;
            letter-spacing: 3px;
        }

        .rating-box:before {
            content: "\f005 \f005 \f005 \f005 \f005";
            position: static;
            font-weight: 900;
        }

        .rating-box .rating {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            white-space: nowrap;
            overflow: hidden;
            color: $secondary-color;
        }

        .rating-box .rating:before {
            content: "\f005 \f005 \f005 \f005 \f005";
            position: static;
            font-weight: 900;
        }
    }
}

/* -------------------------------------------- *
 * Standard Formatted Text Block
 */

.std p {
    margin: 0 0 $b-margin-bottom;
}

.std ol {
    list-style: decimal outside;
    margin-bottom: $b-margin-bottom;
}

.std ol li {
    margin-left: 2em;
}

.std ul {
    list-style: disc outside;
    margin-bottom: $b-margin-bottom;
}

.std ul li {
    margin-left: 2em;
}

.std .note {
    color: $c-text-gray;
    font-size: $f-size-s;
}

/* -------------------------------------------- *
 * Tabs
 */

.tabs {
    margin-bottom: $gap;
    background: #FFFFFF;
}

/* -------------------------------------------- *
 * Toolbar
 */

.toolbar {
    margin-top: $box-spacing;
    margin-bottom: 15px;
    border: none;
    background: none;
    padding: 5px 0 0 0;

    &:after {
        @include clearfix;
    }
}

.pager-no-toolbar {
    margin-bottom: $box-spacing;
}

// This will apply to the bottom toolbar
.pager-no-toolbar~.pager-no-toolbar {
    margin-top: $box-spacing;
}

.toolbar,
.pager {
    color: $c-text;
    line-height: 30px;
    font-size: $f-size-xs;

    @include bp(max-width, $xs-bootstrap) {
        font-size: 16px;
    }
}

.catalog-category-view,
.customer-account{
    .pager{
        display: none;
    }
}


.toolbar label,
.pager-no-toolbar label {
    font-weight: normal;
    text-transform: none;
}

$toolbar-icon-padding-offset: 8px;

.sorter,
.pager {
    float: left;
    margin-bottom: 5px;

    .amount {
        font-size: 1.2rem;
        color: $c-gray;

        @include bp(max-width, $xs-bootstrap) {
            font-size: 1rem;
        }
    }

    label {
        float: left;
        margin-bottom: 0;
        margin-right: 20px;
        font-size: 1.2rem;
        color: $c-gray;
        font-weight: 300;
        @include bp(max-width, $xs-bootstrap) {
            font-size: 1rem;
            height: 35px;
            line-height: 37px;
            margin-right: 5px;
        }

        &:after {
            content: ':';
        }
    }
}

.sorter>.sort-by {
    float: left;
    margin-right: 5px;
    height: 42px;
    vertical-align: top;

    .sort-by-switcher {
        width: 45px;
        height: 30px;
        line-height: 32px;
        display: inline-block;
        text-decoration: none;
        background: $primary-color;
        text-align: center;
        font-size: 0.8rem;
        vertical-align: top;
        border-radius: 5px;
        @include transition-property(background);
        @include transition-duration(300ms);

        @include bp(max-width, $xs-bootstrap) {
            font-size: 1rem;
            height: 30px;
            line-height: 30px;
        }
    }

    .sort-by-switcher--asc {
        color: white;

        &:hover {
            color: white;
            background: darken($c-red, 10%);
        }
    }

    .sort-by-switcher--desc {
        color: white;

        &:hover {
            color: white;
            background: darken($c-red, 10%);
        }
    }
}

.sorter>.view-mode {
    float: right;
    margin: 0;
    margin-left: 30px;
    .grid,
    .list {
        float: left;
        width: 30px;
        height: 30px;
        background: none;
        font-size: 1.2rem;
        line-height: 35px;
        color: $c-gray;
        text-indent: 0;
        text-decoration: none;

        @include bp(max-width, $xs-bootstrap) {
            font-size: 1rem;
            line-height: 35px;
        }

        @include transition-property(color);
        @include transition-duration(300ms);

        span {
            display: none;
            text-indent: -99999px;
        }

        &:after {
            font-family: 'simple-line-icons';
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .grid {
        margin-right: 15px;

        &:after {
            content: "\e06a";
        }
    }

    strong.grid {
        color: $primary-color;
    }

    a.grid {
        color: darken($c-red, 10%);
    }

    .list {
        &:after {
            content: "\e067";
        }
    }

    strong.list {
        color: $gradient-first-color;

    }

    a.list {
        color: darken($c-red, 10%);
    }
}

.pager {
    float: right;
    overflow: hidden;

    &>.count-container {
        float: left;
    }

    .amount {
        float: left;
        font-family: $f-stack-sans;
        white-space: nowrap;
        margin: 0 15px 0 0;
    }

    .limiter {
        float: left;
        height: 30px;
        line-height: 30px;

        &>label {
            padding-right: 5px;

            &:after {
                content: ':';
            }
        }
    }

    .amount,
    .limiter,
    .pages {
        margin-bottom: 5px;
    }

}

.pages {
    float: right;
    overflow: hidden;
    margin-left: 15px;

    strong {
        display: none;
    }
}

.pages li {
    float: left;
}

.pages a,
.pages .current {
    display: inline-block;
    border: 0;
    font-size: $f-size-s;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    line-height: 30px;
    width: 25px;
    height: 30px;
    padding: 0;
    color: $c-action;
    font-family: $f-stack-sans;
}

.pages .current,
.pages .current:hover {
    color: $c-text;
    border: 1px solid $c-module-border;
    width: 30px;
    background-color: #FFFFFF;
    cursor: default;
}

.pages .next,
.pages .previous {
    // New HP5BP technique: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757c9e03dda4e463fb0d4db5a5f82d7
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    background-color: transparent;
    position: relative;

    &:hover {
        border: 1px solid $c-action;
    }
}

.pages .next {
    &:before {
        @include triangle(right, 4px, $c-action);
        top: 50%;
        margin-top: -3px;
        left: 50%;
        margin-left: -2px;
    }

    &:hover:before {
        @include triangle(right, 4px, $c-stimulus);
    }
}

.pages .previous {
    &:before {
        @include triangle(left, 4px, $c-action);
        top: 50%;
        margin-top: -3px;
        left: 50%;
        margin-left: -2px;
    }

    &:hover:before {
        @include triangle(left, 4px, $c-stimulus);
    }
}

@include bp(max-width, $bp-xsmall) {

    // Only hide the "amount" element if page has pagination AND is inside of toolbar (since we only want it hiding on product listing)
    // Otherwise show the amount since there is plenty of space
    .pager .amount--has-pages {
        display: none;
    }

    .pages {
        float: left;
    }

    // When there are 5+ pages, it is important to hide this label in order to not break to three lines
    .limiter label {
        display: none;
    }
}

@include bp(max-width, $bp-medium) {

    // One one column layouts, the toolbar doesn't have to break until a smaller viewport
    .col1-layout {

        .sorter,
        .pager {
            width: 100%;
        }

        .pager {
            float: left;
            clear: both;

            .pages {
                float: left;
                margin-left: 0;
            }

            .count-container {
                float: right;
            }
        }
    }
}

@include bp(max-width, $bp-large) {

    .col2-left-layout,
    .col2-right-layout,
    .col3-layout {

        .sorter,
        .pager {
            width: 100%;
        }

        .pager {
            float: left;
            clear: both;

            .pages {
                float: left;
                margin-left: 0;
            }
        }
    }
}

@include bp(max-width, $xs-bootstrap){
    .toolbar .view-mode>label {
        display: none;
    }
}

/* ============================================ *
 * Tax - Full Tax Summary
 * ============================================ */

#checkout-review-table,
#shopping-cart-totals-table,
body.customer-account .data-table {
    .summary-collapse {
        position: relative;
        cursor: pointer;

        &:before {
            @include triangle(down, 7px, $c-blue);
            position: static;
            display: inline-block;
            margin-right: 5px;
        }

        &:hover:before {
            @include triangle(down, 7px, $c-stimulus);
            position: static;
            display: inline-block;
            margin-right: 5px;
        }
    }

    .show-details {
        .summary-collapse {
            &:before {
                @include triangle(up, 7px, $c-blue);
                position: static;
                display: inline-block;
                margin-right: 5px;
            }

            &:hover:before {
                @include triangle(up, 7px, $c-stimulus);
                position: static;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}

// Center the arrow, as the font is larger in tfoot
#shopping-cart-totals-table tfoot td .summary-collapse {

    &:before,
    &:hover:before {
        margin-bottom: 5px;
    }
}

/* ============================================ *
 * Magento Helpers
 * ============================================ */

.a-center {
    text-align: center;
}

.a-right,
.align-right {
    text-align: right;
}

.no-display {
    display: none !important;
}

.nobr,
.nowrap {
    white-space: nowrap;
}

.width-full {
    width: 100%;
}

@media all and (max-width: 767px) {
    .forcefullwidth_wrapper_tp_banner {
        margin-bottom: 0.25rem !important;
    }
}

/* ============================================ *
 * Custom Helpers
 * ============================================ */

.hidden {
    display: none;
}

/* ============================================ *
 * Print Styles
 * ============================================ */

.page-print .print-head {
    margin: 0 0 15px;
}

.page-print .print-head .logo {
    float: none;
    max-height: 50px;
    width: auto;
}

/* ============================================ *
 * Bootstrap Overrides
 * ============================================ */

@media all and (min-width: 576px) {
    .container {
        max-width: 100%;
    }
}

@media all and (min-width: 768px) {
    .container {
        max-width: 100%;
    }
}

@media all and (min-width: 992px) {
    .container {
        max-width: 100%;
    }
}

@media all and (min-width: 1200px) {
    .fullwidth .container {
        max-width: 100%;
    }

    .container {
        max-width: $max-container-width;
    }
}

@media (min-width: 1280px) {
    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
}

.nav-tabs {
    border: none;
    margin-bottom: 2rem;
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
    
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background: $primary-color;
    color: $c-gray;
    border: none;
    &:focus {
        outline: none;
    }
}

.nav-tabs .nav-link {
    color: gray;
    font-weight: 300;
    border-radius: 0;
    border: none;
    display: block !important;
    font-size: 15px;
    padding: 5px 0;
    @include bp(min-width, $xs-bootstrap+1){
        font-size: 18px;
        font-weight: bold;
    }
    &:hover {
        border: none;
    }
    span{
        background: white;
        padding: 10px;
        display: block;
    }
}

.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

@media (min-width: 540px) {
    .col-sm-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 720px) {
    .col-md-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 960px) {
    .col-lg-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 1140px) {
    .col-xl-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 1400px) {
    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 57%;
        flex: 0 0 57%;
        max-width: 57%;
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 18%;
        flex: 0 0 18%;
        max-width: 18%;
    }
}

/* ============================================ *
 * Bootstrap Overrides
 * ============================================ */


/* ============================================ *
 * Select2 Overrides
 * ============================================ */
$alturaSelect: 30px;

.sorter label,
.pager .amount,
.pager .limiter,
.pager .pages {
    line-height: $alturaSelect;
    font-size: 16px;
    font-weight: 400;

    @include bp(max-width, $xs-bootstrap) {
        height: $alturaSelect;
        line-height: $alturaSelect;
    }
}

.sorter>.sort-by,
.pager .limiter {
    height: $alturaSelect;
    vertical-align: top;

    @include bp(max-width, $xs-bootstrap) {
        height: $alturaSelect;
    }
}

.select2 {
    min-width: 150px;
    display: inline-block;
    vertical-align: top;
    height: $alturaSelect;
    margin-top: 5px;

    @include bp(max-width, $xs-bootstrap) {
        min-width: 60px;
    }

    .selection {
        height: $alturaSelect;
    }
}

.sorter,
.pager {
    .select2 {
        margin-top: 0;
    }
}

.select2Small {
    .select2 {
        min-width: 100px;

        @include bp(max-width, $xs-bootstrap) {
            min-width: 150px;
        }
    }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: $alturaSelect;

    @include bp(max-width, $xs-bootstrap) {
        height: $alturaSelect;
    }

    b {
        border-color: white transparent transparent transparent;
    }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $primary-color;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent white transparent;
}

.pager .amount,
.pager .limiter,
.pager .pages {
    margin-bottom: 0;
}

.select2-dropdown {
    z-index: 5;
}

.select2-container--default {
    &:hover {
        .select2-selection--single {
            background: white;
        }
    }
}

.select2-container--default .select2-selection--single {
    outline: none;
    background: white;
    border: 1px solid $primary-color;
    border-radius: 5px;
    height: $alturaSelect;

    @include bp(max-width, $xs-bootstrap) {
        height: $alturaSelect;
    }

    @include transition-property(background);
    @include transition-duration(300ms);

    .select2-selection__rendered {
        color: $primary-color;
        font-size: 14px;
        font-family: $f-stack-special;
        line-height: $alturaSelect;
        text-align: left;
        font-weight: 600;
        text-transform: unset;

        @include bp(max-width, $xs-bootstrap) {
            font-size: 14px;
            line-height: $alturaSelect;
        }
    }
}

.no-padding-col{
    padding-left: 0;
    padding-right: 0;
}

.owl-stage {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.owl-item{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto !important;
}

.owl-item{
    .item{
        width: 100%;
    }
}

.banner-widget{
    img{
        width: 100%;
    }
}
