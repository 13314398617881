.cms-no-route{
    .main-container{
        padding: 0;
        @include bp(min-width, $xs-bootstrap+1){
            padding: 0 0 0 15px;
        }
        .col-main{
            margin-bottom: 0;
        }
    }
}
.section-404{
    background-color: rgba(255, 255, 255, 0.85);
    background-blend-mode: lighten;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: auto 100%;
    @include bp(min-width, $xs-bootstrap+1){
        background-position: center right;
        background-size: auto 150%;
        background-color: unset;
        background-blend-mode: unset;
    }
    .container-404{
        height: 60vh;
        .titles-404{
            h1{
                color: $primary-color;
                font-size: 55px;
                font-weight: 700;
                display: inline-block;
                margin-bottom: 5px;
                @include bp(min-width, $xs-bootstrap+1){
                    margin-bottom: 15px;
                }
            }
            h2{
                color: $primary-color;
                font-weight: 600;
                display: block;
                padding: 0;
                margin: 0;
                border-left: none;
                @include bp(min-width, $xs-bootstrap+1){
                    display: inline-block;
                    padding-left: 15px;
                    margin-left: 15px;
                    border-left: 1px solid lightgray;
                }
                span{
                    font-weight: 300;
                }
            }
            p{
                margin-top: 15px;
                @include bp(min-width, $xs-bootstrap+1){
                    margin-top: 0;
                }
                a{
                    font-weight: 600;
                }
            }
        }
    }
}
