// =============================================
// Mixin - If-Resolution
// =============================================

// From http://blog.14islands.com/post/37259603246/sass-resolution-mixin

@mixin if-min-resolution($dppx) {
    @include if-resolution(min, $dppx) {
        @content;
    }
}

@mixin if-max-resolution($dppx) {
    @include if-resolution(max, $dppx) {
        @content;
    }
}

@mixin if-resolution($prefix, $dppx) {
    // 1px = 96dpi
    $dpi: $dppx * 96;
    @media
    (-webkit-#{$prefix}-device-pixel-ratio: #{$dppx}),
    (   #{$prefix}--moz-device-pixel-ratio: #{$dppx}),
    (     -o-#{$prefix}-device-pixel-ratio: #{$dppx*2}/2),
    (        #{$prefix}-device-pixel-ratio: #{$dppx}),
    (                #{$prefix}-resolution: #{$dpi}dpi),
    (                #{$prefix}-resolution: #{$dppx}dppx) {
        @content;
    }
}

// ----------------------------------------------
// Usage example:
// ----------------------------------------------

//// Renders on screens with >= 2 resolution
//@include if-min-resolution (2) {
//    div { color:#000; }
//}
//
//// Renders on screens with >= 1.5 resolution
//@include if-min-resolution (1.5) {
//    div { color:#000; }
//}
//
//// Renders on screens with <= 1.5 resolution
//@include if-max-resolution (1.5) {
//    div { color:#000; }
//}
//
//// Renders on screens with <= 2 resolution
//@include if-max-resolution (2) {
//    div { color:#000; }
//}

// ----------------------------------------------

