@mixin loadingOverlay($opacity: 0.8) {
    &:before,
    &:after {
        content:'';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: none;
    }

    &:before {
        background-color: white;
        opacity: $opacity;
        z-index:2;
    }

    &:after {
        background-image: url('../images/opc-ajax-loader.gif');
        background-repeat: no-repeat;
        background-position: center;
        z-index:3;
    }

    &.loading {
        position: relative;

        &:before,
        &:after {
            display: block;
        }
    }
}
