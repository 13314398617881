/* ============================================ *
 * Catalog - MSRP MAP Popup
 * ============================================ */

.cart-msrp-totals {
    color: red;
    font-size: 12px !important;
    font-weight: bold;
    margin: 10px 10px 0;
    padding: 10px;
    text-align: right;
    text-transform: uppercase;
}

.map-cart-sidebar-total {
    color: red;
    display: block;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    padding: 2px 5px;
}

.map-popup {
    background: #FFFFFF;
    border: 1px solid $c-module-border;
    border-radius: 3px; 
    margin: 12px 0 0;
    position: absolute;
    text-align: left;
    width: 450px;
    z-index: 100;

    @include bp(min-width, $bp-medium + 1) {
        &.map-popup-right {
            left: $gap !important;
        }

        &.map-popup-left {
            left: auto !important;
            right: $gap !important;
        }
    }

    @include bp(max-width, $bp-medium) {
        width: 100%;
        left: 0px !important;
    }

    .map-popup-heading {
        padding: 8px 10px;
        margin-right: 40px;
        width: auto;

        h3 {
            font-size: $f-size;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: break-word;
            text-align: left;
            text-overflow: ellipsis;

            @include bp(max-width, $bp-xsmall) {
                text-align: center;
            }
        }
    }

    .map-popup-close {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        height: 36px;
        width: 36px;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
    }

    .map-popup-content {
        border-top: 1px solid $c-module-border-light;
        padding: 10px;
        margin: 0 10px;
        overflow: hidden;
        text-align: left;

        @include bp(max-width, $bp-xsmall) {
            text-align: center;
        }
    }

    .map-popup-checkout {
        padding: $gap 0;

        form:after {
            @include clearfix;
        }

        span {
            display: block;
        }

        .button {
            float: left;
            margin: 0 2px;
            clear: left;
        }

        .additional-addtocart-box {
            float: left;
            min-width: 210px; // fix for a web-kit box-model display bug

            li {
                list-style-type: none;
            }
        }

        .paypal-logo {
            width: auto;

            a {
                display: inline-block;
                float: left;
                clear: left;
            }

            .paypal-or {
                float: left;
                text-align: center;
                padding: 5px 15px;
                clear: left;
            }

            &:after {
                @include clearfix;
            }

            .bml_button {
                a {
                    clear: left;
                }
            }
        }

        @include bp(max-width, $bp-xsmall) {
            text-align: center;

            .button,
            .additional-addtocart-box,
            .paypal-logo a,
            .paypal-logo .paypal-or {
                float: none;
            }

            .additional-addtocart-box {
                min-width: 0px;
            }

            .paypal-logo .paypal-or {
                margin: 0px;
                margin-bottom: 10px;
            }
        }

        &:after {
            @include clearfix;
        }
    }

    .map-popup-price {
        padding: $gap 0;
        @include bp(max-width, $bp-xsmall) {
            text-align: center;
            padding-top: 0px;
        }

        .price-box,
        .price-box .special-price {
            display: inline-block;
            margin: 0;
            padding: 0;
        }

        .price-box .minimal-price-link {
            display: inline-block;
        }
    }

    .map-popup-text {
        padding: 10px 0;
        margin: 0 10px;
        word-wrap: break-word;
    }

    .map-popup-text,
    .map-popup-only-text {
        border-top: 1px solid $c-module-border-light;
    }
}

/* ============================================ *
 * Catalog - MSRP Product Listing
 * ============================================ */

.price-box .map-link {
    display: block;
    text-align: center;
}
