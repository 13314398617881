.cms-contactanos{
    background: #f9f9f9;
    .breadcrumbs{
        display: none;
    }
    .contact-custom{
        margin-bottom: 30px;
        margin-top: 20px;
        .title-header{
            .container-fluid   {
                border-bottom: 4px solid $primary-color;
                padding-left: 0;
                padding-right: 0;
            }
            h2{
                font-weight: 800;
                font-size: 2rem;
                line-height: 3.2rem;
                margin-bottom: 5px;
                letter-spacing: 1px;
                text-transform: uppercase;
                padding-bottom: 0;
                line-height: 2.2rem;
            }
            .title{
                h2{
                    color: $c-gray;
                    margin-bottom: 5px;
                    font-size: 22px;
                    font-weight: bold;
                    text-transform: uppercase !important;
                    padding-bottom: 0;
                }
            }
            .indicador{
                margin-bottom: 25px;
                font-family: inherit;
                font-style: normal;
            }
        }
        .contact-box{
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
            border-radius: 0 25px;
            margin-left: -10px;
            margin-right: -10px;
            @include bp(min-width, $xs-bootstrap+1){
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .left-col{
        padding: 30px 30px 25px;
        background-image: url(../images/bg-login.jpg);
        background-size: cover;
        background-position: 40%;
        -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        background-color: $gradient-first-color;
        background-repeat: no-repeat;
        background-blend-mode: hard-light;
        border-radius: 0 0 5px 5px;
            @include bp(min-width, $xs-bootstrap+1){
                border-radius: 5px 0 0 5px; 
            }
        .contact-info{
            hr{
                border-top: 4px solid white;
                width: 55px;
                margin-bottom: 20px;
            }
            h2{
                color: white;
                font-size: 24px;
                font-weight: 400;
                text-transform: lowercase;
                &:first-letter {
                    text-transform: uppercase;
                    font-size: 21px;
                }
            }
            a{
                color: white;
                font-weight: 300;
                padding-bottom: 10px;
                display: block;
                transition: all .3s;
                &:focus{
                    outline: none;
                }
            }
            i{
                height: 20px;
                width: 20px;
                background: white;
                text-align: center;
                margin-top: 3px;
                margin-right: 8px;
                float: left;
                color: $gradient-first-color;
                color: $primary-color;
                padding-top: 4px;
                border-radius: 25px;
                font-size: 11px;
            }
        }
    }
    .right-col{
        background: white;
        padding: 30px 30px 25px;
        border-radius: 5px 5px 0 0;
        @include bp(min-width, $xs-bootstrap+1){
            border-radius: 0 5px 5px 0; 
        }
        .form-container{
            .page-title{
                display: none;
            }
            .scaffold-form{
                padding-left: 0 !important;
                ul{
                    list-style: none;
                    li{
                        margin-left: 0;
                    }
                    .fields{
                        margin-left: 0;
                    }
                    input{
                        width: 100%;
                        height: 33px;
                        border-radius: 5px;
                        &:focus{
                            outline: none;
                            border-color: $gradient-first-color;
                        }
                    }
                    textarea{
                        width: 100%;
                        max-width: 100%;
                        border-radius: 5px;
                    }
                }
            }
        }
        .buttons-set{
            padding-top: 20px;
            button{
                border-radius: 5px;
            }
        }
        p.required{
            color: $primary-color;
        }
    }
    footer{
        background: #f9f9f9;
    }
}