/* ============================================ *
 * Recurring Profiles
 * ============================================ */

#recurring_profile_list_view {

    th {
        white-space: normal;

        span {
            white-space: inherit;
        }
    }

    @include bp (max-width, $bp-xsmall) {
        font-size: $f-size-xs;

        a {
            font-size: inherit;
        }

        th,
        td {
            padding: 2px;
        }
    } //end bp-xsmall breakpoint

} //end #recurring_profile_list_view

.recurring-profiles-title {
    float: left;

    h1 {
        margin-bottom: $element-spacing;
    }
}

body.customer-account .my-account .title-buttons.recurring-profiles-title-buttons {
    float:right;
    text-align: right;
    margin-bottom: $trim;

    @include bp(max-width, $bp-xsmall) {
        text-align: center;
        float: none;
    }

    .button {
        position: static;
    }
}

.recurring-profiles-messages {
    &:after {
        @include clearfix;
    }
}

#recurring_start_date_trig {
    display: inline;
}
