/* ============================================ *
 * PayPal
 * ============================================ */

.paypal-review-order {
    @include bp(max-width, $bp-medium + 100) {
        @include bp(min-width, $bp-medium + 1) {
            .col2-set {
                .col-1,
                .col-2 {
                    padding: 0px;
                }
            }
        }
    }

    .info-set {
        margin-bottom: $gap;
        padding-bottom: $gap;
        border-bottom: 1px solid $c-fieldset-border-light;
    }

    .buttons-set {
        margin-top: 0px;
        padding-top: 0px;
        border: 0;

        button {
            margin-bottom: $gap;
        }
    }

    @include bp(max-width, $bp-medium) {
        .col-1 {
            padding-bottom: 0;
        }

        .field.inactive {
            display: none;
        }
    }
}


.top-container .bml-ad {
    margin-top: $element-spacing;
    margin-bottom: $element-spacing;
    text-align: center;

    span {
        display: inline-block;

        a {
            display: block;
        }
    }
}

.bml-ad {
    display: none;
    text-align: center;
    margin-bottom: 5px;

    span {
        display: block;
        line-height: 0;
    }
    a {
        display: inline-block;
        max-width: 100%;
    }
    a:hover {
        opacity: 0.7;
    }
    img {
        height: auto !important; // Override inline style
        max-width: 100%;
    }
}

// Prevent small 2px space on right side of image
.cart-totals-wrapper .bml-ad.large img {
    display: block;
    margin: 0;
}

@include bp(max-width, $bp-medium) {
    .bml-ad.small {
        display: block;
    }
}

@include bp(min-width, $bp-medium + 1) {
    .bml-ad.large {
        display: block;
    }
}

div.paypal-logo {
    text-align: center;
    margin: $trim-small 0;
    max-width: 100%;

    span {
        display: block;
        width: 100%;

        a {
            display: inline-block;
            max-width: 100%;

            img {
                max-width: 100%;
            }
        }
    }

    span > img {
        display: none;
    }
}

.checkout-types {
    div.paypal-logo {
        text-align: right;
    }
}

.bml-checkout-type {
    list-style-type: none;
}

.sidebar {
    .paypal-logo {
        text-align: center;
        line-height: 0;

        > a {
            display: inline-block;
            max-width: 100%;

            &:hover {
                opacity: 0.8;
            }
            img {
                display: block;
            }
        }
        .label {
            margin-top: 4px;
            a {
                font-size: $f-size-xs;
                line-height: 1.5;
            }
        }
    }
}
