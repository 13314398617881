/* ============================================ *
 * Popular Search Terms Cloud
 * ============================================ */

.catalogsearch-term-popular .tags-list {
  float: left;
  width: 100%;
}
.catalogsearch-term-popular .tags-list > li {
  float: left;
  height: 40px;
  margin-right: 25px;
}
