/* ============================================ *
 * Catalog - Compare
 * ============================================ */

.block-compare .product-name:after {
    @include clearfix;
}

.compare-table .product-image {
    display: inline-block;
}

.compare-table {
    .product-shop-row {
        &.top td {
            padding-bottom: 0;
            border-bottom: 0;
        }

        &.bottom td {
            padding-top: 0;
        }
    }
}
