.reset-pass-custom,
.forgot-pass-custom{
    margin-bottom: 30px;
    margin-top: 20px;
    .page-title{
        border-bottom: 4px solid $primary-color;
        h1{
            color: #454545;
            margin-bottom: 5px;
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 0px;
        }
    }
    .forgot-form-container{
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        margin-top: 30px;
        border-radius: 5px;
        padding: 30px 30px 25px;
        background: white;
        .icon-container{
            margin-top: -40px;
            margin-bottom: 20px;
            i{
                background: $primary-color;
                height: 60px;
                width: 60px;
                color: white;
                border-radius: 50%;
                font-size: 25px;
                padding-top: 17px;
                box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.2);
            }
        }
        .fieldset{
            width: 100%;
            max-width: 100%;
            margin: 0;
            .required{
                display: none;
            }
            .form-instructions{
                font-family: inherit;
                font-style: normal;
                font-size: inherit;
                font-weight: 300;
            }
            input{
                height: 33px;
                border-radius: 5px;
                width: 100%;
                &:focus{
                    outline: none;
                }
            }
        }
    }
    .success-msg,.error-msg{
        li{
            border-left: none;
            border-radius: 5px;
            background: white;
            border: 1px solid;
        }
    }
    .success-msg li{
        border-color: $c-green;
        color: $c-green;
    }
    .error-msg li{
        border-color: $c-red;
        color: $c-red;
    }
    .buttons-set{
        width: 100%;
        max-width: 100% !important;
        margin-left: 0;
        padding-top: 20px;
        margin-top: 30px;
        button{
            border-radius: 5px;
        }
        .back-link{
            a{
                color: $primary-color;
                border: 1px solid white;
                transition: all .25s;
                border-radius: 5px;
                &:hover{
                    border: 1px solid lightgray;
                    color: gray;
                    border-radius: 5px;
                    text-decoration: none;
                }
            }
        }
    }
}
.customer-account-forgotpassword,
.customer-account-changeforgotten{
    .main-container{
        background: #f9f9f9;
    }
    footer{
        background: #f9f9f9;
    }
}

.reset-pass-custom{
    .forgot-form-container{
        .fieldset{
            margin-top: 0 !important;
            .required{
                display: block !important;
            }
            .form-instructions{
                display: block !important;
            }
        }
    }
}
