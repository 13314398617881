/* ============================================ *
 * Widgets
 * ============================================ */

/* -------------------------------------------- *
 * Add to cart by SKU
 */

.sidebar {
    .widget-addbysku {
        .sku-table {
            .input-text:not(.qty) {
                width: 100%;

                @include bp(min-width, $bp-medium+1) {
                    //prevent widget in left/right column from exceeding column width
                    max-width: 100px;
                }
            }
        }
    }
}

.widget {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
}

.widget .pager {
    float: none;
    width: 100%;
}

// Implement custom grid breakpoints for grids that are contained within widgets
@include bp(min-width, $bp-xlarge) {
    .col2-left-layout,
    .col2-right-layout,
    .col3-layout
    {
        .col-main .widget {
            @include product-grid(4, 960, "widget");
            @include product-grid(5, 960, "widget");
        }
    }
}

@include bp(min-width, $bp-medium) {
    .col1-layout {
        .col-main .widget {
            width: 100%;
            @include product-grid(4, 960, "widget");
            @include product-grid(5, 960, "widget");
        }
    }
}
