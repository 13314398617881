/* ============================================ *
 * Global
 * ============================================ */

html,
body {
    height: 100%;
}

.wrapper {
    min-width: 320px;
    min-height: 100%;
    margin: 0 auto;
    background: #FFFFFF;
}

.wrapper:after {
    @include clearfix;
}
