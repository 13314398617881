/* -------------------------------------------------- */
/* !- General */
html {
    font-size: 16px;
    /* 1400px - 979px - 768px */
    @include bp(max-width, $bp-xxlarge) {
        font-size: 14px;
    }
    @include bp(max-width, $bp-large) {
        font-size: 13px;
    }
    @include bp(max-width, $xs-bootstrap) {
        font-size: 12px;
    }
    .page {
        overflow-x: hidden;
    }
}
.owl-carousel .owl-stage {
    margin-left: auto;
    margin-right: auto;
}

/* -------------------------------------------------- */
/* !- Product */
.zoomContainer {
    z-index: 2;
    .zoomWindow {
        box-sizing: content-box;
        width: 615px !important;
        border-width: 1px !important;
        border-color: $c-module-border !important;
        border-radius: 5px;
        margin-left: 20px;
    }
}
.containerImagenTexto {
    .container-image {
        background: $c-action;
        padding: 2rem;
        &.rounded {
            border-radius: 100%;
        }
    }
    .container-texto {
        font-family: $f-stack-sans;
        font-size: 19px;
        line-height: 30px;
        font-weight: 300;
        color: $c-text;
        text-align: center;
    }
}
/* SUCCESS PAGE Y DETALLE PEDIDO */

/* Lista Archivos:
* html/app/design/frontend/THEME/default/template/detpedido/success_details.phtml
* html/app/design/frontend/THEME/default/template/checkout/success.phtml
*/

.checkout-onepage-success .col-main-wrap {
    padding: 40px 220px 100px;
    background-position: left bottom;
    background-repeat: no-repeat;
}

.titleDestacadoSuccess {
    position: relative;
}


.checkout-onepage-success{
    .icon-success-container{
        color: white;
        display: flex;
        justify-content: center!important;
        margin-bottom: 20px;
        margin-top: 20px;
        i{
            background: $primary-color;
            height: 50px;
            width: 50px;
            border-radius: 50px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .page-title {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        h1 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase;
            font-weight: 500;
            color: $primary-color;
        }
    }
    .sub-title {
        font-size: 32px;
        line-height: 41px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 4px 0 40px;
        padding: 0;
        color: $c-gray;
    }
    .pedidoNumber{
        a {
            color: $third-color;
        }
    }
    .paraDescargar {
        margin-bottom: 40px !important;
        font-weight: 500;
        a {
            font-weight: 500;
            color: white;
            background: $primary-color;
            padding: 5px 10px;
            border-radius: $default-border-radius;
            &:hover{
                text-decoration: none;
            }
        }
    }
    #my-orders-table{
        tfoot{
            border-bottom: 4px solid white;
            tr{
                background: transparent;
                border: none;
                td{
                    border: none;
                }
            }
        }
        tbody{
            border-bottom: 1px solid lightgray;
            &:last-child{
                border-bottom: none;
                @include bp(min-width, $xs-bootstrap+1){
                    border-bottom: 1px solid lightgray;
                }
            }
        }
    }
    #my-payments-table{
        tr{
            border-bottom: 1px solid lightgray !important;
            &:last-child{
                border-bottom: none !important;
            }
            td{
                border-bottom: none;
            }
        }
    }
    .order-details{
        background: #f5f5f5;
        border-radius: 10px;
    }
    .payment-details{
        margin-bottom: 20px;
        margin-top: 10px;
        @include bp(min-width, $xs-bootstrap+1){
            margin-bottom: 40px;
            margin-top: 20px;
        }
        h3{
            color: $primary-color;
            font-weight: 900;
        }
    }
    .buttons-set{
        button{
            border-radius: $default-border-radius;
        }
    }
}


.checkout-onepage-success p {
    font-size: 14px;
    font-weight: 400;
    color: #5B5B5B;
}

.checkout-onepage-success .pedidoNumber {
    margin-bottom: 20px !important;
    font-weight: 500;
}



.checkout-onepage-success #my-orders-table.data-table th {
    padding: 5px;
    font-size: 14px;
    line-height: 40px;
    font-weight: 400;
    color: #9C9C9C;
    border-color: #bfbfbf;
}

.checkout-onepage-success .data-table .product-name {
    text-transform: none;
    color: #5B5B5B;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
}

.checkout-onepage-success .data-table td {
    text-transform: none;
    color: $c-gray;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
}

.checkout-onepage-success .data-table .subtotal td,
.data-table tfoot tr.first td {
    padding-right: 0;
    padding-left: 0;
    border-right: 0;
    border-left: 0;
    border-top: 1px solid #bfbfbf;
    color: #9C9C9C;
    box-shadow: none;
}
.grayBackground {
    padding-top: .5rem!important;
    @media all and (min-width: 992px) {
        padding-top: 1.5rem!important;
    }
    position: relative;
    z-index: 1;
    &:after {
        content:"";
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        background: $backgroundGray;
        height: 100%;
        width: 200%;
        transform: translateX(-25%);
    }
}
.checkout-onepage-success .data-table tfoot td,
.data-table tfoot tr td {
    padding-right: 0;
    padding-left: 0;
    border: none;
    border-top: 1px solid #bfbfbf;
    color: #9C9C9C;
    font-weight: 400;
    padding: 15px !important;
}

.checkout-onepage-success .data-table tfoot .grand_total td {
    color: #4A4A4A;
    font-weight: 500;
}

.checkout-onepage-success .titleResponsive {
    display: none;
}

@media all and (max-width:980px) {
    .checkout-onepage-success .col-main-wrap {
        padding: 20px 60px 50px 80px;
        background: none;
    }
}

@media all and (max-width:768px) {
    .checkout-onepage-success .col-main-wrap {
        padding: 20px 20px;
    }

    .titleDestacadoSuccess:before {
        display: none;
    }

    #ac-page .form select,
    .selectTienda select {
        width: 100%;
        max-width: 100%;
    }
}

@media all and (max-width:767px) {
    #my-orders-table tfoot {
        width: 100%;
        display: table;
    }

    .checkout-onepage-success #my-orders-table.data-table th {
        display: none;
    }

    .checkout-onepage-success .titleResponsive {
        font-weight: 700;
        display: block;
    }

    .checkout-onepage-success #my-orders-table tfoot .grand_total,
    .checkout-onepage-success #my-orders-table tfoot .shipping,
    .checkout-onepage-success #my-orders-table tfoot .subtotal {
        display: block;
        width: 100%;
    }

    .checkout-onepage-success #my-orders-table tfoot .grand_total:after,
    .checkout-onepage-success #my-orders-table tfoot .shipping:after,
    .checkout-onepage-success #my-orders-table tfoot .subtotal:after {
        content: "";
        clear: both;
    }

    .checkout-onepage-success #my-orders-table tfoot .grand_total td,
    .checkout-onepage-success #my-orders-table tfoot .shipping td,
    .checkout-onepage-success #my-orders-table tfoot .subtotal td {
        display: block;
        width: 50%;
        float: left;
        border: none;
        text-align: right !important;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

/* SUCCESS PAGE Y DETALLE PEDIDO */

.webpay-payment-endtransaction,
.webpay-payment-validatetransaction{
    .col-main{
        width: 100%;
    }
    .col-right.sidebar{
        display: none;
    }
}

.failed-transaction-container{
    .icon-failed-container{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        i{
            background: $third-color;
            color: white;
            height: 50px;
            width: 50px;
            border-radius: 50px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    h2{
        color: $third-color;
        font-weight: 700;
    }
    .failed-transaction-info{
        p{
            &:nth-child(2){
                color: $third-color;
                display: inline;
                border: 1px solid $third-color;
                border-radius: 5px;
                padding: 5px 15px;
                br{
                    display: none;
                }
            }
            &:nth-child(3){
                margin-top: 40px;
                margin-bottom: 0;
            }
        }
        ul{
            background: #f5f5f5;
            padding: 20px 10px;
            border-radius: 10px;
            font-weight: 300;
        }
    }
}
