/* ============================================ *
 * Header
 * ============================================ */
 .page-header {
    margin: 0 auto;
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 100;

    .topHeader {
        background: $header-color;
    }

    .topHeader,
    .bottomHeader {
        padding: 0;
    }

    .bottomHeader {
        background: $primary-color;
        .postHeader {
            .nav-contenido {
                width: 50%;
                display: inline-block;
                font-size: 1rem;
                font-weight: 300;
            }
            .nav-secondary li {
                display: inherit;
                max-width: 100%;
                padding-right: 15px;
                margin-left: 15px;
                a {
                    line-height: 2.5rem;
                }
            }
            @include bp(max-width, $xs-bootstrap) {
                display: none !important;
            }
        }
    }
}

.page-header-container {
    position: relative;
}

.header-language-container,
.page-header {
    font-family: $f-stack-sans;
}

/* ============================================ *
 * Language switcher + welcome message
 * ============================================ */

.header-language-background {
    padding: 5px;
    background-color: $bg-color;
    margin: 0;
    text-align: center;

    .header-language-container {
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;
    }

    &:after {
        @include clearfix;
    }

    .form-language,
    .currency-switcher {
        float: left;
        margin-right: $gap;
    }

    .welcome-msg {
        float: none;
        font-family: $f-stack-sans;
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: .875rem;
        color: $primary-color;
        font-weight: 400;
    }
}

.page-header-container .store-language-container {
    float: right;
    max-width: 35%;
    padding: 0;

    label {
        display: none;
    }

    select {
        max-width: 100%;
    }
}

@include bp(max-width, $bp-xsmall) {
    .page-header-container .store-language-container {
        padding-top: 8px;

        select {
            // Setting this font-size will causing zooming on iOs devices, but it keeps header more tidy
            font-size: $f-size-xs;
        }
    }
}

.header-language-background,
.header-language-background a {
    color: $c-text-white;
}

@include bp(max-width, $bp-medium) {

    .header-language-background {
        display: none;
    }

}

/* ============================================ *
 * Logo
 * ============================================ */

.logo {
    display: inline-block;
    width: auto;
    min-width: 100px;
    min-height: 44px;
    text-align: center;
    img {
        max-width: 100%;
    }

    .small {
        display: block;
    }

    .large {
        display: none;
    }
}
// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

    .logo {
        max-width: 360px;
        min-height: 90px;

        .small {
            display: none;
        }

        .large {
            display: block;
        }
    }

}

@include bp(max-width, $xs-bootstrap - 1px) {
    .staticMobile {
        position: static;
        padding: 0;
        -ms-flex: 0;
        flex: 0;
        max-width: 0;
    }
}

/* ============================================ *
 * Skip Links
 * ============================================ */

.skip-links {
    clear: both;
    align-items: end;
    align-content: end;
}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

    .skip-links {
        border: 0;
    }

}

/* -------------------------------------------- *
 * Skip - Link
 */

.skip-link {
    position: relative;
    float: left;
    width: 20%;
    height: 44px;
    color: $c-text;
    line-height: 48px;
    text-align: center;

    @media all and (max-width: 769px) {
        line-height: normal;
        height: auto;
        display: block;
    }

    &.skip-nav,
    &.skip-search {
        @media all and (max-width: 769px) {
            width: 25% !important;
        }
    }

    &.skip-account {
        width: 50% !important;
    }

    &.skip-cart {
        @media all and (min-width: 769px) {
            width: 100% !important;
        }
    }
}

a.skip-link {
    text-decoration: none;
    color: $primary-color;
    position: relative;
    font-size: 1rem;
    font-weight: 400;

    .simple-icon {
        font-size: 20px;
        @include bp(min-width, $xs-bootstrap) {
            font-size: 23px;
            margin-right: 2px;
        }
    }
}

.skip-link:not(.skip-active):hover {
    opacity: 0.8;
}


/* -------------------------------------------- *
 * Skip Link - Active
 */
@include bp(max-width, $bp-medium) {

    .skip-link.skip-active {
        color: $secondary-color;
    }
    .skip-link span.label {
        display: none;
    }
    .skip-search.skip-link.skip-active {
        z-index: 1000;
        position: absolute;
        left: calc(0px - 72%);
        top: 2px;
        background: none;
        color: white;
        &:focus{
            outline: none;
        }
        @include bp(max-width, $bp-xsmall){
            left: calc(0px - 70%);
        }
        .icon{
            &:after,&:before {
            position: absolute;
            top: 22px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $secondary-color;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
        .label{
            display: none;
        }
    }
    .skip-link.skip-active span {
        color: inherit;
    }

}

.skip-search.skip-link.skip-active{
    left: calc(-50% - 18%);
    @include bp(min-width, $bp-xsmall+1){
        left: calc(0px - 73%);
    }
    @include bp(min-width, $bp-small+1){
        left: calc(0px - 39%);
    }
    @include bp(min-width, $bp-medium+1){
        left: calc(0px - 20%);
    }
}


@include bp(min-width, $xs-bootstrap) {

    .skip-link .icon {
        margin-right: 3px;
    }

}

/* -------------------------------------------- *
 * Skip Link - Label
 */

.skip-link .label {
    display: none;
}

// ---------------------------------------------

@include bp(min-width, $xs-bootstrap+1) {

    .skip-link .label {
        display: inline;
    }

}

/* ============================================ *
 * Skip Content
 * ============================================ */

.skip-content {
    display: none;

    @include bp(max-width, $xs-bootstrap) {
        display: none !important;
    }
}
.skip-content.skip-active#header-search {
    display: block !important;
    background: $primary-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 10;
    padding: 10px;
}
.skip-content.skip-active {
    display: block !important;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1;
}

/* ============================================ *
 * Global Header Navigation
 * ============================================ */
@include bp(max-width, $xs-bootstrap) {
    #nav {
        .nav-primary {
            a.level0, .nav-primary a {
                line-height: 45px;
            }
            ul.level0>li {
                padding: 0;
                > a {
                    line-height: 35px;
                    &:after{
                        margin-top: -17px;
                    }
                }
            }
        }
    }
}
@include bp(max-width, $bp-medium) {
    // ---------------------------------------------
    // Link

    #header-account li a,
    .nav-primary a.level0 {
        padding: 0 15px 0 15px;
        border-bottom: none;
        text-align: left;
        color: $c-gray;
        text-transform: uppercase;
        line-height: 30px;
    }

    #header-account li:last-child a,
    .nav-primary li.level0:last-child a.level0 {
        border-bottom: 0;
    }

    .no-touch #header-account a:hover,
    .no-touch .nav-primary a:hover {
        background-color: rgba(0, 0, 0, 0.2);
        text-decoration: none;
    }

}

.account-cart-wrapper {
    position: static;
    width: 100%;
    text-align: center;

    @include bp(max-width, $bp-medium) {
        position: static;
        width: 50%;
        display: block;
    }
}


// ----- HEADER CHECKOUT 

.onestepcheckout-index-index {
    #header {
        .page-header-container {
            max-width: 100%;
            background-color: $c-red;
            text-align: center;

            a.logo {
                max-width: 1280px;
                padding: 0 15px;
                margin: 0 auto;
                display: block;
                float: none;
            }
        }
    }
}

.header-minicart{
    .product-details{
        .qtd-valor{
            color: $secondary-color;
            font-weight: 600;
        }
        .eliminar{
            .fa-trash-alt{
                font-size: 18px;
            }
        }
    }
    .block-subtitle{
        border-bottom: 1px solid lightgray;
    }
    #header-cart{
        .product-name{
            a{
                justify-content: start;
                font-size: 12px;
                -webkit-box-align: start !important;
            }
        }
    }
    .block-content{
        margin: 0;
        margin-left: 10px;
        margin-right: 10px;
        border-top: 1px solid $primary-color;
        .subtotal {
            padding: 14px 0 5px;
            color: gray;
            border-top: 1px solid lightgray;
        }
    }
    .minicart-actions{
        .checkout-button{
            background: $primary-color;
            padding: 10px !important;
            border-radius: 5px;
        }
        .cart-link{
            color: $primary-color;
        }
    }
    .skip-content.skip-active{
        @include bp(max-width, $xs-bootstrap) {
            height: auto;
            width: calc(100vw + 1px);
            box-shadow: 0px 10px 32px -8px rgba(0,0,0,0.46);
            left: unset;
            right: 0;
            top: 70px;
            padding-bottom: 10px;
            -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            
            @-webkit-keyframes slide-in-top {
                0% {
                    -webkit-transform: translateY(-1000px);
                            transform: translateY(-1000px);
                    opacity: 0;
                }
                100% {
                    -webkit-transform: translateY(0);
                            transform: translateY(0);
                    opacity: 1;
                }
            }
            @keyframes slide-in-top {
                0% {
                    -webkit-transform: translateY(-1000px);
                            transform: translateY(-1000px);
                    opacity: 0;
                }
                100% {
                    -webkit-transform: translateY(0);
                            transform: translateY(0);
                    opacity: 1;
                }
            }  
            .block-subtitle{
                border-bottom: 1px solid lightgray;
                padding: 15px 5px 15px 5px;
            }     
        }  
    }
}

/* styles ipad portrait (768px) */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 768px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) 
  {
    .top-menu-left{
        display: block !important;
    }
}

