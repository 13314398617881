
/* ============================================ *
 * Checkout - Layout
 * ============================================ */

 .cart-table {
    float: left;
    width: 70%;
}

.cart .button {
    white-space: normal;
}

.cart-forms,
.cart-totals-wrapper,
.crosssell {
    float: right;
    clear: right;
    width: 30%;
    padding-left: $box-spacing-large;
}
.cart-forms {
    .shipping {
        display: none;
    }
}
#shopping-cart-table{
    background: $bg-color;
    border-radius: 5px;
    @include bp(max-width, $bp-small){
        background: white;
    }
}
.cart-totals,
.cart-forms .discount,
.cart-forms .giftcard,
.cart-forms .shipping {
    padding: $box-spacing + 10px;
    background-color: $bg-color;
    border: none;
    border-radius: 5px;
}

.cart-table,
.cart-totals,
.cart-forms .discount,
.cart-forms .giftcard,
.cart-forms .shipping {
    margin-bottom: $box-spacing-large;
}

.checkout-cart-index {
    #postcode {
        width: 100%;
    }
}

@mixin oneColumnCartItems {
    .cart-table {
        float: none;
        width: 100%;
    }

    .cart-forms {
        float: left;
        padding-right: $box-spacing;
        padding-left: 0;
    }

    .cart-totals-wrapper,
    .crosssell {
        padding-left: $box-spacing;
    }

    .cart-forms,
    .cart-totals-wrapper,
    .crosssell {
        width: 50%;
    }

    .crosssell {
        clear: right;
        float: right;
    }
}

.display-both-prices {
    @include oneColumnCartItems;
}

@include bp(max-width, $bp-large) {
    .display-single-price {
        @include oneColumnCartItems;
    }
}

@include bp(max-width, 599px) {
    .product-cart-sku {
        display: none;
    }

    .display-both-prices,
    .display-single-price {
        .cart-forms,
        .cart-totals-wrapper,
        .crosssell {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            float: none;
            width: 100%;
        }
    }
}

.display-single-price {
    &:after {
        @include clearfix;
    }
}
/* ============================================ *
 * Checkout - Cart
 * ============================================ */

.cart{
    .page-title {
        margin-bottom: 15px;
        border-bottom: 1px solid $c-module-border-light;
    
        &:after {
            @include clearfix;
        }
        h1 {
            float: left;
            font-size: 20px;
            position: relative;
            color: $c-gray;
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: 4px solid $primary-color;
            padding-bottom: 8px;
            margin: 0;
        }
    }
    .cart-footer-actions{
        @include bp(max-width, $bp-xsmall){
            padding: 25px 0 5px 0 !important;
        }
        .button2 {
            background: $primary-color;
            height: 33px;
            transition: .3s;
            &:hover {
                background: $primary-color-dark;
            }
            &.btn-empty{
                border: 1px solid $primary-color;
                &:hover {
                    background-color: $primary-color-dark;
                    border-color: $primary-color-dark;
                }
                span{
                    color: white;
                    @include bp(max-width, $bp-xsmall){
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                    }
                }
            }
        }
        @include bp(max-width, $bp-xsmall){
            justify-content: space-between;
            display: flex;
        }
    }
    h2.product-name{
        a{
            @include bp(max-width, $bp-xsmall){
                font-size: 16px;
                color: $c-gray;
            } 
        }
    }
}

.checkout-types {
    float: right;
    text-align: right;
    max-width: 100%;
    width: 100%;

    li {
        &:after {
            @include clearfix;
        }
        vertical-align: top;
        margin: 0 0 5px 5px;

        img {
            display: inline;
            vertical-align: top;
        }
    }
    li:first-child {
        margin-left: 0;
    }
    /* We always want this shipping method to display on its own line */
    .method-checkout-cart-methods-multishipping {
        display: block;
    }

    &.top {
        li {
            display: inline-block;
        }

        .bml_button {
            display: inline-block;
            vertical-align: top;

            img {
                display: block;
            }
        }

        .paypal-logo .paypal-or {
            margin-top: 5px;
        }
    }

    &.bottom,
    &.minicart {
        .paypal-logo {
            a {
                display: block;
            }
        }
        .paypal-or {
            margin: 0px;
            display: block;
            text-align: center;
        }
    }
}

@include bp(min-width, 741px) {
    .checkout-types.bottom {
        .paypal-or {
            text-align: right;
            padding-right: 70px;
        }
    }
}

.cart-totals .checkout-types .btn-checkout {
    margin-bottom: 0;
    width: 100%;
    line-height: 29px;
    font-size: 1rem;
    font-weight: 700;
}

@include bp(max-width, $bp-medium) {
    .checkout-types {
        float: none;
        text-align: center;

        &.bottom,
        &.minicart {
            .paypal-or {
                width: auto;
                float: none;
            }
        }
    }

    .checkout-types li {
        float: none;
        width: 100%;
        margin-left: 0;
    }
}

@include bp(max-width, $bp-small) {
    .btn-checkout {
        width: 100%;
    }
}


.cart-table {
    font-family: $f-stack-sans;

    th,
    td,
    tbody td {
        // Adding tbody for extra selector weight
        border-bottom: none;
        vertical-align: top;
    }

    h2 {
        color: $c-h2;
        font-size: $f-size;
        font-weight: 600;
        margin-bottom: 0;
        @include bp(max-width, $bp-small){
            height: 65px;
            margin-bottom: 35px;
        }
        @include bp(max-width, $bp-xsmall){
            height: 70px;
            margin-bottom: 20px;
        }
        @include bp(max-width, $bp-xxsmall){
            height: 65px;
            margin-bottom: 10px;
        }
    }
    thead tr {
        border: none;
    }

    thead th,
    tbody td {
        background-color: transparent;
        padding: 20px;
        @include bp(max-width, $bp-small){
            padding: 10px 0;
        }
    }
    .product-cart-image {
        padding-right: 5px;
        max-width: 100px;
    }
    .product-cart-info {
        padding-left: 5px;
        padding-right: 5px;
    }
    .product-cart-actions {
        padding-left: 20px;
    }

    tr {
        border-top: 1px solid $c-table-border;
    }

    tfoot {
        tr {
            background: none;
        }

        tr > td:after {
            @include clearfix;
        }
    }

    span.or {
        font-size: 9px;
        padding: 0 5px;
        text-transform: uppercase;
        font-family: $f-stack-special;
    }

    .product-cart-image .cart-links,
    .product-cart-info .btn-remove,
    .product-cart-actions .button {
        display: none;
    }

    .product-cart-image {

        .product-image img {
            max-width: 100%;
            width: 100%;
            border: 1px solid $primary-color;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: white;
        }

        a.cart-edit {
            display: none;
        }
    }

    .product-cart-sku {
        font-family: $f-stack-sans;
        font-size: 0.8rem;
        line-height: 1rem;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;

        .label {
            font-weight: 600;
        }
    }

    .btn-empty {
        float: left;
        height: 33px;
        line-height: 30px;
        transition: .3s;
    }

    .product-cart-total,
    .product-cart-price {
        text-align: center;
    }

    .cart-tax-total {
        position: relative;
        cursor: pointer;

        &:after {
            @include triangle(left, 6px, $c-text-primary);
            position: absolute;
            top: 3px;
            right: -11px;
        }

        &.cart-tax-total-expanded {
            &:after {
                @include triangle(down, 6px, $c-text-primary);
                right: -15px;
                top: 6px;
            }
        }
    }
    .cart-links {
        display: none;
    }

    .cart-links > li {
        white-space: nowrap;
        line-height: 1.3;
        margin-bottom: 5px;
        min-width: 65px;
        font-size: $f-size-s;

        > a {
            display: block;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .product-cart-actions {
        min-width: 60px;
        text-align: center;

        .qty {
            height: 33px;
            border-color: $c-input-border;
            border-radius: 0;
            margin-bottom: 10px;
            text-align: center;
            width: 3.2em;
            border-radius: 5px;
        }

        .button {
            margin-bottom: 5px;
            border-radius: 5px;
        }
        .btn-update{
            font-size: 14px;
            padding: 0px;
            border-radius: 50px;
            height: 30px;
            width: 30px;
            position: absolute;
            left: 100px;
            bottom: 16px;
            box-shadow: 0 5px 15px rgba(0,0,0,.2);
            @include bp(min-width, $bp-small+1){
                position: unset;
                border-radius: 5px;
                padding: 5px 10px;
                font-size: 10px;
                height: 33px;
                width: auto;
                box-shadow: none;
            }
            span{
                display: none;
                @include bp(min-width, $bp-small+1){
                    display: unset;
                    font-size: 10px;
                }
            }
            @include bp(min-width, $bp-small+1){
                i{
                    margin-right: 5px;
                }
            }
        }
    }

    .item-options {
        padding-left: 15px;
    }
}

@include bp(max-width, $bp-medium) {
    .cart-table {

        th {
            font-size: $f-size-xs;
        }

        th,
        td {
            padding: 7px 6px;
        }

        .product-cart-actions > li {
            white-space: inherit;
        }
    }
}

@include bp(max-width, 699px) {
    .display-both-prices {
        .cart-table {
            thead th.cart-total-head,
            td.product-cart-total {
                display: none;
            }
        }
    }
}

@include bp(max-width, $bp-small) {
    .cart-table {
        colgroup, thead {
            display: none;
        }

        tr {
            display: block;
            margin-bottom: 20px;
            padding-bottom: 0px;
            padding-top: 10px;
            position: relative;
            width: 100%;

            &:after {
                @include clearfix;
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }

        tfoot tr {
            padding-bottom: 0;
        }

        td {
            border: none;
            display: block;

            &[data-rwd-label] {
                padding-left: 0px;
                padding-top: 15px;
                .price {
                    font-weight: normal;
                }

                &:before {
                    content: attr(data-rwd-label) ":";
                    font-size: 10px;
                    padding-right: 5px;
                    text-transform: uppercase;
                }
            }

            &.product-cart-price {
                text-align: left;
                width: 40%;
                padding-top: 16px;
                display: inline-block;
                float: unset;
            }
        }

        h2 {
            font-size: 0.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .cart-links {
            padding-top: 5px;
            padding-right: 5px;

            > li {
                white-space: normal;
                text-align: center;

                > a {
                    padding: 2px 0px;
                }
            }
        }

        .cart-tax-info {
            font-style: italic;
            padding-left: $trim-small;
            font-size: $f-size-s;

            .price {
                font-size: $f-size-s;
            }
        }

        .product-cart-image {
            width: percentage(1/4);
            float: left;
            padding-bottom: 0;

            a.cart-edit {
                display: block;
                font-size: $f-size-xl;
                text-align: center;
                text-transform: uppercase;
            }
        }


        .product-cart-actions,
        .product-cart-price {
            padding-bottom: 0;
            padding-top: 0;
            float: right;
        }

        .product-cart-remove .btn-remove ,
        .product-cart-image .cart-links,
        .product-cart-total,
        .product-cart-actions .cart-links {
            display: none;
        }

        .product-cart-info .btn-remove {
            display: block;
        }

        // This element will be displayed via JS once the related input is focused
        .product-cart-actions .button {
            display: none;
        }

        .product-cart-info {

            .btn-remove {
                position: absolute;
                right: 0;
                bottom: 0px;
                height: 60px;
                width: 15%;
                padding: 10px;
                background-position: center;
                border-left: 1px solid lightgray;
                padding-top: 30px;
            }

            .product-cart-sku {
                margin-bottom: 5px;
            }
        }

        .product-cart-actions {
            text-align: left;
            width: 40% !important;
            display: inline-block;
            float: left !important;
            .qty {
                margin-right: 7px;
                margin-bottom: 0px;
                &:focus{
                    outline: none;
                }
            }
        }

        .price,
        .product-cart-price:before {
            font-weight: 500;
            font-size: $f-size-l;
            font-family: $f-stack-sans;
        }

        .cart-footer-actions {
            text-align: center;
            width: 100%;

            #empty_cart_button {
                float: right;
            }
            .btn-update,
            span.or {
                display: none;
            }
            @include bp(max-width, $xs-bootstrap){
                .btn-empty,
                .btn-continue{
                    width: 48%;
                    span{
                        font-size: 12px;
                        line-height: 33px;
                    }
                }
            }
        }
    }

    .display-both-prices {
        .cart-table {
            td {
                &[data-rwd-tax-label] {
                    &:before {
                        content: attr(data-rwd-tax-label) ":";
                    }
                }
            }
        }
    }
}

/* ============================================ *
 * Checkout - Estimate Shipping and Tax
 * ============================================ */

.shipping {

    h2 {
        font-size: $f-size-xs;
        font-weight: bold;
        margin: 0 0 5px;
    }

    select {
        max-width: 100%;
        height: 30px;
        display: block;
        border: 1px solid $c-input-border;
    }

    select.validation-failed {
        border-color: $c-danger;
    }

    .shipping-desc {
        display: none;
    }

    .buttons-set {
        border: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .form-list {
        &:after {
            @include clearfix;
        }
        li {
            float: left;
            margin: 5px 2% 10px 0;
        }

        .shipping-country {
            width: 48%;
        }

        .shipping-region {
            width: 48%;
        }

        .shipping-postcode {
            margin-right: 0;
            width: 48%;

            input {
                margin-top: 4px;
            }
        }

        .input-box {
            padding-top: 0;
        }

        input {
            height: 30px;
            margin-top: 4px;
        }

        label {
            font-family: $f-stack-special;
            font-size: $f-size-s;
            font-weight: 400;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    .sp-methods {
        padding: 10px 0 0;
        text-align: left;

        dd {
            margin-bottom: 10px;
        }

        label {
            font-family: $f-stack-serif;
            font-size: $f-size-xs;
            font-style: italic;
            min-width: 100px;

            span {
                font-family: $f-stack-special;
                font-weight: bold;
                font-style: normal;
            }
        }
    }

    #co-shipping-method-form .buttons-set .button {
        float: left;
        margin-left: 0;
    }
    #co-shipping-method-form .sp-methods dd label {
        border: 1px solid $c-module-border;
        background-color: $c-module-background-hover;
        min-width: 220px;

        &:hover {
            background-color: shade($c-module-background, 10%)
        }
    }
}

@include bp(max-width, $bp-medium) {

    .shipping .shipping-form .form-list > li {
        width: 100%;
        float: none;

        label {
            display: block;
        }

        input,
        select {
            width: 100%;
        }
    }
}

// =============================================
// Totals
// =============================================

.cart .cart-totals {
    text-align: right;
    min-height: 188px;
    @include bp(max-width, $bp-xsmall){
        min-height: auto;
        padding: 20px 20px 15px 20px;
        .a-right{
            text-align: left;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                text-align: right;
            }
            strong{
               color: $primary-color;
               font-weight: 600;
               font-size: 20px;
            }
        }
    }

    &:after {
        @include clearfix;
    }

    table {
        font-size: $f-size-xs;
        margin-bottom: 20px;
        text-transform: uppercase;
        width: 100%;
        @include bp(max-width, $bp-xsmall){
            margin-bottom: 0;
        }
        td {
            padding: 2px 0px;
        }
        td:first-child {
            padding-right: 10px;
            min-width: 120px;
        }

        tbody tr:last-child td,
        tbody tr:last-child th {
            padding-bottom: 10px;
        }

        tfoot {
            border-top: 1px solid #E6E6E6;
            border-bottom: 1px solid #E6E6E6;
            @include bp(max-width, $bp-xsmall){
                border-bottom: none;
            }
            td {
                font-size: 20px;
                padding: 2px 5px;
                vertical-align: top;
            }

            strong span,
            span.price {
                font-family: $f-stack-sans;

            }

            strong {
                font-weight: 600;
            }
        }
    }
}

@include bp(max-width, 600px) {

    .cart-totals {
        text-align: right;
    }
}

@include bp(max-width, $bp-medium) {

    .cart .cart-totals table tfoot td {
        font-size: $f-size-xxl;
    }
}

// =============================================
// Discount codes and gift cards
// =============================================

.discount-form:after,
#giftcard-form:after {
    @include clearfix;
}

#discount-coupon-form,
.cart .giftcard {
    width: 100%;

    h2 {
        display: none;
    }

    label {
        font-family: $f-stack-special;
        font-size: $f-size-xs;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
        min-width: 105px;
        display: inline-block;
        margin-right: 10px;
    }

    .field-wrapper {
        display: flex;
        position: relative;
    }

    .validation-advice {
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: -18px;
    }

    .button-wrapper {
        display: block;
        vertical-align: bottom;
        box-sizing: border-box;
        float: none;

        > button {
            float: none;
            background: $primary-color;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            span{
                text-transform: capitalize;
            }
            @include bp(max-width, $bp-xsmall){
                height: 45px;
            }
        }
    }

    .input-text {
        display: block;
        height: 33px;
        margin: 0;
        box-sizing: border-box;
        float: none;
        margin-right: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: none;
        @include bp(max-width, $bp-xsmall){
            height: 45px;
            margin-right: 0px;
            border-right: none;
        }
    }
    .button2{
        height: 33px;
        line-height: 33px;
    }
}

.cart .giftcard p {
    margin-bottom: $element-spacing;
}

.cart .giftcard .check-gc-status {
    float: left;
    padding: 0px;

    > span > span {
        font-size: $f-size;
        text-transform: none;
    }
}

/* ============================================ *
 * Checkout - Cart Cross sell
 * ============================================ */

.crosssell {
    h2 {
        color: $c-blue;
    }

    .item a.product-image {
        width: auto;
        float: left;
    }
}

/* Change the layout to 2 columns at a breakpoint that is higher than a 3 columns layout would normally break */
@include bp(max-width, $bp-large) {
    .crosssell {
        .products-grid > li:nth-child(even),
        .products-grid > li:nth-child(3n),
        .products-grid > li {
            width: percentage(210 / 440);
            margin-right: percentage(20 / 440);
        }

        .products-grid > li:nth-child(odd) {
            clear: left;
        }

        .products-grid > li:nth-child(even) {
            margin-right: 0;
        }

        /* Undo three-column config */
        .products-grid > li:nth-child(3n+1) {
            clear: none;
        }
    }
}

@include bp(max-width, $bp-small) {
    .cart-table {
        .cart-tax-total {
            &:after {
                right: -9px;
            }
            &.cart-tax-total-expanded {
                &:after {
                    right: -13px;
                }
            }
        }
    }
}

@include bp(max-width, 320px) {
    .crosssell {
        ul {
            .item {
                padding: 0 0px 40px;
            }

            .product-details .crosssell-actions {
                padding: 0 5px;
            }
        }
    }
}

.checkout-cart-index{
    .checkout-types{
        width: auto;
        .button{
            border-radius: 5px;
        }
        &.top{
            @include bp(max-width, $bp-medium){
                display: none;
            }
            .button{
                margin-top: -2px;
            }
        }
        &.bottom{
            @include bp(max-width, $bp-small){
                position: fixed;
                z-index: 99;
                bottom: 0;
                width: 100%;
                left: 0;
                border-radius: 0px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px -3px 26px;
                height: 70px;
                background: white;
                padding: 10px;
                .btn-checkout{
                    height: 50px;
                    font-size: 15px;
                }
            }
        }
    }
    .messages{
        .success-msg{
            li{
                border-left: none;
                background-color: white;
                border-radius: 6px;
                color: $c-green;
                border: 1px solid $c-green;
                &:before {
                    border-left: 6px solid $c-green;
                }
            }
        }
    }
    .btn-remove{
        width: 20px;
        height: 20px;
        transition: all .25s;
        margin-top: 5px;
        background-image: none;
        text-indent: 0;
        padding-top: 10px;
        &:before{
            content: "\f1f8";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 1.2rem;
            color: $third-color;
        }
    }
    .product-cart-price{
        .cart-price{
            .price{
                color: $primary-color;
                font-size: 1.3rem;
                font-weight: 900;
            }
            @include bp(max-width, $bp-small){
                font-size: 1.4rem;
            }
        }
    }
    .product-cart-total{
        .cart-price{
            .price{
                font-size: 1.3rem;
                color: $c-gray; 
            }
        }
    }
    .cart-table tr:first-child{
        border: none;
    }
    .header-minicart{
        .minicart-actions{
            @include bp(max-width, $xs-bootstrap){
                padding-bottom: 0;
            }
            .cart-link{
                display: none;
            }
        }
    }
    .discount-form{
        .input-text{
            width: 100%;
        }
    }
}
