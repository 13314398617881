/* ============================================ *
 * Account - Reviews
 * ============================================ */

.product-review {
    .product-img-box {
        p.label {
            border-bottom: 1px solid $c-module-border;
            font-size: $f-size-xl;
            font-family: $f-stack-special;
            margin-top: 20px;
            padding-bottom: $gap;
            text-transform: uppercase;
        }

        .rating-box {
            margin: 15px 0;
        }
    }

    .product-details {
        h2 {
            border-bottom: 1px solid $c-module-border;
            color: #3399CC;
            font-size: $f-size-xl;
            font-weight: 600;
            font-family: $f-stack-special;
            padding-bottom: $gap;
        }
    }

    .ratings-table {
        font-family: $f-stack-special;

        th {
            padding-right: $gap;
        }

        tr {
            float: left;
            font-size: $f-size-xs;
            margin-right: $gap;
            text-transform: uppercase;
        }
    }

    .ratings-description {
        dt {
            border-bottom: 1px solid $c-module-border;
            font-size: $f-size-xl;
            font-weight: 400;
            font-family: $f-stack-special;
            margin-top: 20px;
            padding: $gap 0;
            text-transform: uppercase;
        }

        dd {
            color: #737373;
            font-family: $f-stack-special;
            font-size: $f-size-s;
        }
    }
}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {
    .product-review {
        .ratings-table {
            tr {
                float: none;
            }
        }
    }
}

// ---------------------------------------------

@include bp(min-width, 1126px) {
    .product-review {
        .ratings-table {
            tr {
                float: left;
                margin-right: 15px;
            }
        }
    }
}

// ---------------------------------------------

@include bp(max-width, 320px) {
    .product-review {
        .ratings-table {
            tr {
                float: none;
            }
        }
    }
}
