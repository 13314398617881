/* ============================================ *
 * CMS Pages
 * ============================================ */

// ----------------------------------------------
// Content for static pages

.cms-page-view,
.cms-no-route {
    .page-title,
    .page-head {
        h1,
        h3 {
            border: none;
        }
    }

    .std {

        img {
            max-width: 100%;
        }

        p {
            color: $c-text;
            font-size: 14px;
            line-height: 24px;
        }

        h1 {
            color: $c-text-primary;
        }

        h2 {
            color: $c-text-primary;
        }

        li {
            color: $c-text;
            font-size: $f-size;
            line-height: 24px;
        }
    }

    .col-left {
        &:after {
            @include clearfix;
        }
    }
}

.cms-no-route {
    .std {

        img {
            width: 100%;
        }
    }
}

.cms-page-view .std,
.cms-home .std,
.cms-no-route .std {
    .messages {
        margin-bottom: $box-spacing;

        ul {
            margin-bottom: 0;
        }

        li {
            list-style: none;
            margin-left: 0;
            font-style: normal;
        }
    }
}

// ----------------------------------------------
// Accordition

#accordion {
    @include accordion;

    li {
        position: relative;
        overflow: hidden;

        .plusimage h2 {
            margin-right: $gap;
        }
    }
}

// ----------------------------------------------
// Sitemap

.page-sitemap {
    .links {
        display: block;
        text-align: right;
        margin: 0 20px 0 0;

        a { position: relative; }
    }

    .sitemap {
        padding: 0 20px;
        margin: 15px 0;
        font-size: $f-size-xs;

        li {
            margin: 3px 0;
        }
    }
}

// ----------------------------------------------
// Contact page

.contacts-index-index {
    h1 {
        border: none;
    }
}

#contactForm {
    padding-left: 40px;

    h2 {
        display: none;
    }

    &:after {
        @include clearfix;
    }

    .fieldset .legend + .required {
        margin: 0;
    }
}

@include bp(max-width, $bp-medium) {

    #contactForm {
        padding-left: 0;
    }
}
