.cms-terminos-y-condiciones{
    .col-auto{
        width: 100%;
        padding: 0;
    }
    .title{
        width: 100%;
        border-bottom: 1px solid lightgray;
        h2{
            line-height: 40px;
            font-size: 20px;
            color: $c-gray;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 0px;
            text-align: left;
            padding: 0;
            border-bottom: 4px solid $primary-color;
            display: table;
        }
    }
    .text-header{
        margin-top: 20px;
    }
    .accordion{
        .icon-arrow-down{
            &:before{
                position: absolute;
                right: 15px;
                top: 18px;
            }
        }
        .drop.collapsed {
            border-bottom: 1px solid white;
            background: white;
            h5{
                color: $c-gray;
                transition: all .25s;
                margin-left: 0px;
            }
            .simple-icon{
                transition: all .2s ease;
                &:before{
                    transform: rotate(0deg);
                    transition: all .2s ease;
                }
            }
        }
        .drop {
            border-bottom: 1px solid lightgray;
            transition: all .25s;
            background: rgba(243, 243, 243, .5);
            h5 {
                color: $primary-color;
                transition: all .25s;
                margin-left: 15px;
            }
            .simple-icon{
                &:before{
                    transform: rotate(180deg);
                    transition: all .2s ease;
                }
            } 
        }
        .card{
            margin: 10px 0;
            border-radius: 5px !important;
            border: 1px solid lightgray !important;
            cursor: pointer;
            .card-body{
                p{
                    font-weight: 300;
                }
                a{
                    color: $primary-color;
                    font-weight: bold;
                }
                ul{
                    li{
                        font-weight: 300;
                    }
                }
            }
        }
    }
    .faq-banner{
        margin-top: 20px;
        border-radius: 10px;
    }
}

