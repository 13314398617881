/* ============================================ *
 * Header - Cart
 * ============================================ */


// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

    .skip-cart {
        width: auto;
        text-align: center;
        padding: 0 10px;
        float: none;
    }

    #header-cart.skip-active {
        display: block;
        position: absolute;
        z-index: 200;
        top: 90px;
        right: 15px;
        left: unset;
        width: 320px;
        height: auto;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -4px;
    }

}

/* -------------------------------------------- *
 * Skip Cart Notifier
 */

.skip-link.skip-active .count {
    color: white;
}

.skip-cart {    
    &:hover {
        text-decoration: none;
    }
    .count {
        font-size: 0.8rem;
        width: 25px;
        height: 25px;
        border: 2px solid white;
        @include border-radius(100%);
        background: $secondary-color;
        display: block;
        position: absolute;
        bottom: 15px;
        left: 50%;
        color: white;
        line-height: 20px;
        text-align: center;
        transform: translateX(0px);
        @include bp(min-width, $xs-bootstrap) {
            bottom: -12px;
            left: -5px; 
            transform: unset;
        }
    }
}

/* -------------------------------------------- *
 * Mini Cart - Empty
 */

.cart-menu .empty {
    padding: 20px;
    text-align: center;
}

/* -------------------------------------------- *
 * Mini Cart - Full
 */

/* Actions */

.cart-menu .actions {
    overflow: hidden;
    padding: 15px;
}

.cart-menu .actions .button {
    float: left;
    width: 48%;
}

.cart-menu .actions .button + .button {
    margin-left: 4%;
}

/* Subtotal */

.cart-menu .subtotal {
    padding: 0 15px 15px;
    font-family: $f-stack-special;
    font-size: 24px;
    line-height: 1.2;
}

/* List */

.mini-cart-list {
    margin-bottom: 5px;
}

.mini-cart-list li {
    position: relative;
    min-height: (15px + 60px + 15px);
    padding: 15px 15px 15px (15px + 60px + 15px);
    border-top: 1px solid $c-module-border;
    font-size: $f-size-s;
    line-height: 1.35;
}

.mini-cart-list .product-name {
    display: inline-block;
}

.mini-cart-list .product-image {
    position: absolute;
    left: 15px;
    top: 15px;
}

.mini-cart-list .product-image img {
    width: 60px;
    height: 60px;
}

//.mini-cart-list .details { }

.mini-cart-list .has-options {
    margin-bottom: 0;
    color: $c-text-gray;
    font-size: $f-size-xs;
}

/* Too full - additional items will be shown in cart */
.cart-menu .last-added {
    padding: 10px 15px 15px;
    border-top: 1px solid $c-module-border;
    font-size: $f-size-s;
}
