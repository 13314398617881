.user-account-nav{
    &:last-of-type{
        border-bottom: none !important;
    }
    .block-title{
        border-bottom: 1px solid lightgray !important;
        padding: 18px 15px 5px;
        margin-top: 13px;

        @include bp(max-width, $xs-bootstrap){
            padding-top: 0 !important;
        }
        @include bp(min-width, $xs-bootstrap +1){
            padding: 15px 0px 7px;   
            margin-bottom: 20px;
        }
        strong{
            @include bp(min-width, $xs-bootstrap +1){
                border-bottom: 4px solid $gradient-secondary-color;
                padding-bottom: 6px;
            }
            @include bp(max-width, $xs-bootstrap){
                padding-left: 0px !important;
            }
            &::after{
                @include bp(max-width, $xs-bootstrap){
                    position: absolute;
                    font-family: "simple-line-icons";
                    font-weight: 900;
                    content: "\e604";
                    color: gray;
                    font-size: 13px;
                    right: 0;
                    transform: translateY(-50%);
                    top: 16px;
                    transition: all .4s ease;
                    transform: rotate(0deg);
                }
            }
        }
    }
    .block-title.active{
        strong{
            &:after{
                @include bp(max-width, $xs-bootstrap){
                    transform: rotate(-180deg);
                }
            }  
        }     
    }
    .block-content{
        background: white;
        @include bp(max-width, $xs-bootstrap){
            border-radius: 0px 0px 7px 7px;
            border: 1px solid lightgray !important;
            border-top: none !important;    
            padding: 5px 15px !important;
            transition: all .4s ease;
            opacity: 1;
            animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
        li{
            font-family: inherit;
            font-weight: 300;
        }
    }
    .block-content.no-display{
        opacity: 0;
    }
}

.customer-account{
    .success-msg,.error-msg{
        li{
            border-left: none;
            border-radius: 5px;
            background: white;
            border: 1px solid;
        }
    }
    .success-msg li{
        border-color: $c-green;
        color: $c-green;
    }
    .error-msg li{
        border-color: $c-red;
        color: $c-red;
    }
    .my-account{
        .page-title{
            border-bottom: 1px solid lightgray;
            margin-top: 27px;
            margin-bottom: 20px;
            h1{
                color: #454545;
                font-size: 22px;
                font-weight: bold;
                text-transform: uppercase !important;
                display: table;
                border-bottom: 4px solid $gradient-secondary-color !important;
                margin-bottom: 0;
                padding-bottom: 5px;
            }
        }
        .count-container{
            font-family: inherit !important;
            select{
                background: white;
                height: 30px;
                border-color: lightgray;
                &:focus{
                    outline: none;
                }
            }
            .limiter{
                label{
                    font-family: "Lato", sans-serif;
                    font-weight: 300;
                }
            }
        }
    }
    /* flex grillas user */
    .page{
        background: #F2F2F2;
    }
    .main{
        @include bp(min-width, $xs-bootstrap+1){
            display: flex;
            flex-wrap: wrap;
            .user-account-nav{
                height: 100%;
                .block-content{
                    height: calc(100% - 120px);
                    background: white;
                    border-radius: 5px;
                    padding: 20px 20px 50px;
                }
            }
        }
    }
    &.customer-account-index{
        .main{
            .user-account-nav{
                .block-content{
                    height: calc(100% - 65px);
                }
            }
        }
    }
    .left-user{
        display: none;
    }
}

table#my-orders-table,
table#my-reviews-table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    //table-layout: fixed;
    border-radius: 5px;
    thead{
        tr{
            border-bottom: 1px solid lightgray;
            &:last-child{
                border-bottom: 1px solid lightgray;
            }
        }
    }
    .detail{
        a{
            color: $primary-color;
            font-weight: 400;
            border: 1px solid $primary-color; 
            padding: 8px 15px;
            border-radius: 5px;
            transition: all .25s;
            &:hover{
                background: $primary-color;
                color: white;
                text-decoration: none;
            }
        }
    }
    caption {
        font-size: 1.5em;
        margin: .5em 0 .75em;
    }
    tr {
        //background-color: #f8f8f8;
        border: 1px;
        border-bottom: 1px solid lightgray;
        padding: .35em;
        &:last-child{
            border-bottom: none;
        }
    }
    thead{
        tr{
            background: lightgray;
        }
    }
    th, td {
        padding: .625em;
        text-align: center;
        font-weight: 300;
        border-bottom: none;
    }
    th {
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
        font-weight: 400;
        background: transparent;
    }
    td{
        padding: 15px 5px;
        border-bottom: none;
    }
}
  
@include bp(max-width, $xs-bootstrap) {
    table#my-orders-table,
    table#my-reviews-table {
        border: 0;
        caption {
            font-size: 1.3em;
        }
        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }
        td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 12px;
            text-align: right;
            &::before {
            /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
                content: attr(data-label);
                float: left;
                font-weight: bold;
                text-transform: uppercase;
                font-size: .8em;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    #my-orders-table{
        tbody{
            display: block;
            width: 100%;
        }
    }
}

#my-orders-table{
    tr{
        @include bp(min-width, $xs-bootstrap+1){
            width: 100%;
        }
    }
    .detail-btn{
        @include bp(min-width, $xs-bootstrap+1){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        a{
            color: $gradient-secondary-color;
            font-size: 11px;
            border: 1px solid $gradient-secondary-color;
            border-radius: 5px;
            padding: 5px 5px;
            transition: all .3s;
            &:first-child{
                margin-bottom: 0px;
            }
            @include bp(min-width, $xs-bootstrap+1){
                font-size: 10px;
                min-width: 110px;
                &:first-child{
                    margin-bottom: 5px;
                }
            }
            &:hover{
                text-decoration: none;
                color: white;
                background: $primary-color;
            }
        }
    }
}

.my-account{
    table{
        background: white;
    }
    #wishlist-table{
        box-shadow: none;
    }
    .product-review{
        .product-img-box{
            width: 100%;
            @include bp(min-width, $xs-bootstrap+1){
                width: 15%;
                min-width: 125px;
                padding-right: 0;
            }
            .product-image{
                width: 125px;
                height: auto;
                margin: 0 auto;
                &:hover{
                    border: 1px solid lightgray;
                }
            }
            p{
                display: none;
            }
        }
        .product-details{
            width: 100%;
            float: left;
            @include bp(min-width, $bp-large+1){
                width: 85%;
            }
            
            .product-name{
                font-family: inherit;
                color: $c-gray;
                padding-bottom: 10px;
            }
        }
        h3{
            font-size: 14px;
        }
        .ratings-table{
            box-shadow: none;
            margin-bottom: 15px;
        }
        dt{
            background: $secondary-color;
            color: white;
            font-weight: 400;
            padding: 5px 10px;
            border-radius: 5px;
            display: table;
            margin-bottom: 10px;
        }
        dd{
            font-weight: 300;
        }
    }
    .buttons-set{
        .back-link{
            a{
                color: gray;
                i{
                    margin-right: 5px;
                }
            }
        }
        button{
            border-radius: 5px;
        }
    }
    .dashboard{
        .dashboard-container{
            padding: 5px 0px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            @include bp(min-width, $xs-bootstrap+1){
                background: white;
                padding: 25px 20px;
            }
            .welcome-msg{
                padding-bottom: 20px;
            }
            .box-recent{
                order: 13;
                margin-top: 20px;
                margin-bottom: 0;
                @include bp(min-width, $xs-bootstrap+1){
                    margin-top: 30px;
                    padding-bottom: 0;
                }
            }
        }
        .box-account{
            padding: 20px;
            margin-bottom: 20px;
            border-bottom: none;
            background: white;
            border-radius: 5px;
            @include bp(min-width, $xs-bootstrap+1){
                background: transparent;
                padding: 0 0 20px 0;
            }
            &:last-child{
                padding-bottom: 0;
                margin-bottom: 0;
            }
            .box-head{
                margin-top: 0;
                a{
                    font-size: 11px;
                    color: $third-color;
                    &:after{
                        content: "\f044";
                        font-family: "Font Awesome 5 Free";
                        margin-left: 8px;
                    }
                }
            }
            p{
                font-weight: 300;
                font-family: "Lato", sans-serif;
                font-style: normal;
            }
            .box{
                .box-title{
                    h3{
                        font-size: 13px;
                    }
                    a{
                        border: 1px solid $third-color;
                        color: $third-color;
                        border-radius: 5px;
                        padding: 5px 10px;
                        line-height: unset;
                        margin-top: 10px;
                        font-size: 11px;
                        display: table;
                        transition: all .25s;
                        &:hover{
                            background: $third-color;
                            color: white;
                            text-decoration: none;
                        }
                    } 
                }
                address{
                    font-weight: 300;
                    font-family: inherit;
                    font-style: normal;
                }
            }
            .box-content{
                a{
                    color: $third-color;
                }
            }
        }
        .col2-set {
            .col-1,
            .col-2 {
                width: 100%;
                max-width: 100%;
                @include bp(min-width, $xs-bootstrap+1){
                    width: 50%;
                    max-width: 50%;
                    padding-right: unset;
                    border-right: 1px solid lightgray;
                    padding-top: 0;
                    margin-top: 20px;
                    &:last-child{
                        border-right: none;
                    }
                }
            }
        }
        .box-reviews{
            li{
                .number{
                    background: $third-color;
                    height: 25px;
                    width: 25px;
                    text-align: center;
                    color: white;
                    border-radius: 50px;
                    padding-top: 4px;
                }
                .details {
                    margin-left: 0px;
                    padding-left: 35px;
                }
            }
        }
    }
}

.shadow-container{
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.customer-account-edit{
    .fieldset{
        background: white;
        border-radius: 5px;
        padding: 25px 20px;
        p.required{
            width: 100%;
        }
        h2{
            font-size: 15px;
            font-weight: 600;
        }
        input{
            height: 33px;
            border-radius: 5px;
            &:focus{
                outline: none;
                border-color: $secondary-color;
            }
        }
        .control{
            margin-bottom: 0;
            .checkbox{
                margin-top: 0;
            }
        }
    }
}

.customer-address-form{
    .scaffold-form{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .fieldset{
            background: white;
            border-radius: 5px;
            padding: 25px 20px;
            margin-bottom: 20px;
            flex: 0 0 100%;
            max-width: 100%;
            &:first-child{
                border-radius: 5px;
            }
            @include bp(min-width, $bp-large+1){
                flex: 0 0 50%;
                max-width: 50%;
                border-radius: 0 5px 5px 0;
                &:first-child{
                    border-radius: 5px 0 0 5px;
                }
            }
            h2{
                font-size: 15px;
                font-weight: 600;
            }
            input{
                height: 33px;
                border-radius: 5px;
                width: 100%;
                transition: all .5s;
                &:focus{
                    outline: none;
                    border-color: $secondary-color;
                }
            }
            p.required{
                color: $primary-color;
                width: 100%;
            }
            select{
                width: 100%;
                max-width: 100%;
                height: 33px;
                background: white;
                border-radius: 5px;
                border-color: $c-input-border;
                margin-top: 0;
                padding: 0 10px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                &:focus{
                    outline: none;
                }
            }
            .field {
                position: relative;
                .selectField:after {
                    width: 0;
                    height: 0;
                    padding: 0;
                    font-family: "simple-line-icons";
                    font-weight: 900;
                    content: "\e604" !important;
                    color: gray;
                    font-size: 9px;
                    border: none;
                    right: 20px;
                    top: 41px;
                    position: absolute;
                }
            }
        }
        .buttons-set{
            width: 100%;
            margin-top: 0;
        }
        .control{
            height: 33px;
            label{
                display: block;
                border-radius: 3px;
            }
        }
    }
}

.customer-address-index{
    .title-buttons{
        button{
            border-radius: 5px;
        }
    }
    .addresses-list{
        background: white;
        border-radius: 5px;
        padding: 25px 20px;
        .addresses-primary{
            h2{
                display: none !important;
            }
            h3{
                font-size: 15px;
                border-bottom: 1px solid lightgray;
                padding-bottom: 7px;
            }
            address{
                font-weight: 300;
            }
            .item{
                &:last-child{
                    margin-top: 30px;
                }
            }
        }
        .col-1,.col-2{
            padding-top: 0px;
        }
        a{
            border: 1px solid $third-color;
            color: $third-color; 
            border-radius: 5px;
            padding: 5px 10px;
            transition: all .25s;
            &:hover{
                color: white;
                background: $third-color;
                text-decoration: none;
            }
        }
    }
    .addresses-additional{
        h2{
            font-size: 15px;
            border-bottom: 1px solid lightgray;
            padding-bottom: 7px;
            font-weight: 600;
        }
        .empty{
            border: 1px solid $secondary-color;
            border-radius: 5px;
            padding: 0 10px;
        }
    }
}

.sendfriend-product-send{
    .main{
        display: flex;
        margin-top: 20px;
        .col-main{
            position: relative;
            width: 100%;
            padding-right: 0px;
            padding-left: 0px;
            flex: 0 0 100%;
            max-width: 100%;
            @include bp(min-width, $xs-bootstrap+1){
                flex: 0 0 80%;
                max-width: 80%;
                padding-right: 15px;
                padding-left: 15px;
                margin-left: 10%;
            }
            @include bp(min-width, $bp-large+1){
                flex: 0 0 50%;
                max-width: 50%;
                margin-left: 0;
            }
        }
    }
    .send-friend{
        h1{
            font-weight: 900;
            letter-spacing: 0.5px;
            font-size: 22px;
            margin-bottom: 5px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 20px;
            @include bp(min-width, $xs-bootstrap+1){
                text-align: left;
            }
        }
        h2{
            font-size: 15px;
        }
        .fields{
            display: flex;
            flex-direction: column;
            @include bp(min-width, $xs-bootstrap+1){
                flex-direction: row;
            }
            .field{
                width: 100%;
                @include bp(min-width, $xs-bootstrap+1){
                    width: 50%;
                    &:first-child{
                        padding-right: 5px;
                    }
                    &:nth-child(2){
                        padding-left: 5px;
                    }
                }
            }
            &.additional-row{
                padding-top: 10px;
                border-top: 1px solid lightgray;
                @include bp(min-width, $xs-bootstrap+1){
                    padding-top: 0;
                    border-top: none;
                }
                .field{
                    &:nth-child(2){
                        padding-left: 0;
                    }
                    label{
                        @include bp(min-width, $xs-bootstrap+1){
                            display: none;
                        }
                    }
                    @include bp(min-width, $xs-bootstrap+1){
                        &:nth-child(2){
                            padding-right: 5px;
                        }
                        &:nth-child(3){
                            padding-left: 5px;
                        }
                    }
                }
                p{
                    position: absolute;
                    right: 10px;
                    margin-top: 17px;
                    @include bp(min-width, $xs-bootstrap+1){
                        right: 25px;
                        margin-top: -10px;
                    }
                    a{
                        border-radius: 50%;
                        color: white;
                        background: $primary-color;
                        border-color: white;
                        &:after{
                            color:white;
                        }
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .input-text{
            width: 100%;
            max-width: 100%;
            height: 33px;
            border-radius: 5px;
        }
        textarea{
            height: 80px;
            min-height: 80px;
        }
        .fieldset{
            &:nth-child(2){
                .legend{
                    margin-top: 30px;
                }
            }
        }
        .buttons-set{
            margin: 15px 0 10px;
            padding-top: 15px;
            .button{
                border-radius: 5px;
            }
        }
        form{
            background: white;
            padding: 20px 20px 5px;
            border-radius: 5px;
        }
    }
    .col-right{
        display: none;
        @include bp(min-width, $bp-large+1){
            display: block;
            flex: 0 0 50%;
            max-width: 50%;
            padding-top: 45px;
            .mini-products-list{
                .item{
                    border-bottom: 1px solid lightgray;
                    margin: 15px 0;
                    padding-bottom: 15px;
                }
                .link-cart{
                    display: none;
                }
                .product-name{
                    font-size: 16px;
                }
                .price-box{
                    font-size: 14px;
                }
                .btn-remove{
                    border-radius: 50%;
                }
            }
        }
    }
    .page{
        background: #F2F2F2;
    }
}

.my-wishlist{
    .fieldset{
        background: white;
        border-radius: 5px;
        padding: 10px;
        @include bp(min-width, $xs-bootstrap+1){
            padding: 20px;
        }
    }
}

.sales-order-view{
    .print-button{
        display: flex;
        flex-direction: column;
        @include bp(min-width, $xs-bootstrap+1){
            flex-direction: row;
        }
    }
    .table-caption{
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 20px;
    }
}

.table-pedidos-detalle{
    padding: 10px;
    .linearize-table-large tbody td[data-rwd-label]{
        padding-left: 0px;
    }
}

.order-detail-info{
    @include bp(min-width, $xs-bootstrap+1){
        background: white;
        padding: 20px;
        border-radius: 5px;
    }
    .order-info-box{
        background: white;
        padding: 0 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        @include bp(min-width, $xs-bootstrap+1){
            background: transparent;
            padding: 0;
            margin-bottom: 0;
        }
    }
    .box-title{
        border-bottom: 1px solid lightgray;
        padding-bottom: 7px;
        h2{
            font-size: 15px;
            font-weight: 600;
            display: inline-block;
            margin-bottom: 0;
        }
    }
    .box-content{
        padding: 20px 0 20px;
        font-weight: 300;
    }
    .order-date{
        background: $primary-color;
        display: table;
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
    }
}

.print-container{
    display: none;
    @include bp(min-width, $bp-large+1){
        display: flex;
    }
    a{
        margin: 0 10px;
    }
}

.customer-account{
    input.checkbox{
        height: 18px !important;
        width: 18px !important;
        margin-top: 9px !important;
    }
    .sidebar .block-reorder{
        display: none;
    }
}
