/* ============================================ *
 * Nav - Skip Link
 * ============================================ */

.skip-nav {
	width: 40px;

	&:not(.skip-active):hover {
		text-decoration: none;

		.icon {
			background-position: -50px+4px ($toolbar-icon-padding-offset/2);
		}
	}
}

.skip-nav {
	position: relative;
}

.skip-nav .label {
	display: block;
	position: relative;
	top: -25px;
	left: 0;
	font-size: 0.825rem;
	line-height: 1rem;
	color: white;
	font-weight: 100;
	font-family: $f-stack-sans;
}

.skip-nav .icon {
	&:after {
		content: "\f0c9";
		font-family: "Font Awesome 5 Free";
	}

	font-weight: 900;
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: inherit;
	width: 40px;
	height: 65px;
	background: none;
	padding-top: 20px;
	padding-bottom: 20px;
}
li.levelBackground {
	display: none !important;
}
.header-desplegable {
	#header-nav {
		display: none;
	}

	.nav-primary {
		width: 100%;
		@media all and (min-width: 768px) {
			background: $primaryHeader;
		}

		li {
			position: static;
			@media all and (min-width: 768px) {
				&.menu-active {
					>a {
						color: $c-gray;
						background: #EDEDED;
					}
				}

				>a {
					color: white;
					@include transition-property(background);
					@include transition-duration(300ms);
					border: none;

					&:hover,
					&:active,
					&:focus {
						color: $secondary-color;
					}
				}
			}
		}
		@media all and (min-width: 768px) {
			ul.level0 {
				width: 100%;
				border: none;
				padding: 15px;
				min-height: 140px;
				background: #EDEDED;
				max-width: 65%;
				top: 60px;

				li {
					a {
						padding: 0 20px;
						font-size: 14px;
						font-weight: 400;
						line-height: 40px;
					}
					&.levelBackground {
						float: none;
						display: none;
						width: 100%;
						max-width: 35%;
						height: 100%;
						background: $activeHeader;
						border: none;
						padding: 15px;
						position: absolute;
						top: 0;
						left: 830px;
						max-width: calc(1282px - 100%);
						box-sizing: border-box;
						img {
							max-width: 100%;
							height: auto;
						}
					}
				}
			}
		}
		.menu-active ul.level0 li.levelBackground {
			display: none !important;
		}
		@media all and (min-width: 768px) {
			.menu-active ul.level0 li.levelBackground {
				display: block !important;
			}
		}
	}
}
.menuActive {
	.menu-col-desplegable {
		.menu-link-desplegable{
			background: $activeHeader;
		}
	}

	#header-nav {
		display: block;
		width: 100%;
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		max-width: 100%;
		margin: 0 auto;
		background: white;

		#nav .nav-secondary {
			display: none !important;
		}
	}
}
.menu-col-desplegable {
	@media all and (max-width: 768px) {
		display: none !important;
	}
}
.menu-link-desplegable {
	color: white;
	text-align: center;
	cursor: pointer;
	border: 1px solid white;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: all .5s;
}

.header-desplegable{
	@include bp(min-width, $xs-bootstrap+1){
		#nav {
			background: white;
			z-index: 2;
			border-bottom-left-radius: 10px;
		}
		&.menuActive{
			#header-nav{
				@include bp(min-width, $xs-bootstrap+1){
					display: flex;
					width: auto;
					left: 50%;
					margin-left: -400px;
					top: 125px;
					height: 450px;
					border-bottom-left-radius: 20px;
					box-shadow: none;
					.nav-primary{
						background: white;
						display: flex;
						flex-direction: column;
						min-width: 300px;
						li.active{
							a{
								color: $secondary-color;
							}
							.level0{
								li a{
									color: $c-gray;
								}
							}
						}
						li{
							ul.level0{
								top: 0;
								left: 300px;
								height: 100%;
								min-width: 465px;
								max-width: 465px;
								padding: 0;
								height: 450px;
								border-bottom-right-radius: 10px;
								box-shadow: none;
								.levelBackground{
									display: none !important;
								}
								.level1{
									background: #EDEDED;
								}
							}
						}
						.level0{
							&.parent{
								a.has-children{
									&:after{
										font-family: "simple-line-icons";
										font-weight: 900;
										content: "\e604";
										position: absolute;
										right: 10px;
										font-size: 10px;
										transform: rotate(-90deg);
										color: gray;
									}
								}
							}
						}
					}
					&:after{
						content: "";
						display: block;
						background: rgb(255,255,255);
						background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,.3) 47%, rgba(0,0,0,.6) 100%);
						width: 200vw;
						height: 100vh;
						position: absolute;
						z-index: 0;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				
			}
		}
		.nav-primary .menu-active>ul.level0,
		.nav-primary li.level0 li.sub-menu-active>ul {
			display: block;
			@include bp(min-width, $xs-bootstrap){
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				flex-direction: column;
				flex-basis: 100%;
				flex: 1;
			}
		}
	}
	.nav-primary{
		li > a {
			color: $c-gray;
			width: 100%;
		}
	}
}

// ---------------------------------------------

@include bp(min-width, 500px) {

	.skip-nav {
		width: 25%;
	}

}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

	.skip-nav {
		display: none;
	}

}

/* ============================================ *
 * Nav - Skip Content
 * ============================================ */

 @include bp(max-width, $bp-medium) {

	#header-nav {
		padding: 5px 0;
	}

}

@-webkit-keyframes slide-in-left {
	0% {
	  -webkit-transform: translateX(-1000px);
			  transform: translateX(-1000px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	  opacity: 1;
	}
  }
  @keyframes slide-in-left {
	0% {
	  -webkit-transform: translateX(-1000px);
			  transform: translateX(-1000px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	  opacity: 1;
	}
  }


@include bp(max-width, $xs-bootstrap) {
	.skip-content.skip-active#header-nav {
		position: fixed;
		z-index: 100;
		padding: 0;
		height: 100%;
		width: 95vw;
		background: white;
		box-shadow: -8px 0px 21px 5px rgba(0,0,0,0.53);

		-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

	}
}

.top-menu-left{
	background: $primary-color;
	font-weight: bold;
	text-transform: uppercase;
	padding: 10px 0 10px 10px;
	color: white;
	height: 41px;
	a{
		padding: 10px;
		margin-top: -10px;
		margin-right: 6px;
	}
	p{
		margin: 0;
	}
	i{
		color: white;
		font-size: 17px;
	}
}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

	#header-nav {
		display: block;
		/* Force visibility */
	}

}

/* ============================================ *
 * Nav
 * ============================================ */
 nav#nav {
	position: relative;

	@include bp(max-width, $xs-bootstrap) {
		position: static;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
	}
}

@include bp(max-width, $xs-bootstrap){
	#header-account{
		position: fixed;
		z-index: 100;
		padding: 0 !important;
		height: 100%;
		width: 75vw;
		left: unset;
		right: 0;
		background: white;
		-webkit-box-shadow: 7px 0px 29px 0px rgba(0,0,0,0.53);
		-moz-box-shadow: 7px 0px 29px 0px rgba(0,0,0,0.53);
		box-shadow: 7px 0px 29px 0px rgba(0,0,0,0.53);

		-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		a{
			&:hover{
				color: white !important;
				text-decoration: none;
			}
		}
	}
	@-webkit-keyframes slide-in-right {
		0% {
			-webkit-transform: translateX(1000px);
					transform: translateX(1000px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateX(0);
					transform: translateX(0);
			opacity: 1;
		}
	}
	@keyframes slide-in-right {
		0% {
			-webkit-transform: translateX(1000px);
					transform: translateX(1000px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateX(0);
					transform: translateX(0);
			opacity: 1;
		}
	}
	#header-account{
		li{
			&:nth-child(5){
				a{
					background: $gradient-bg;
					color: white;
					border-radius: 5px;
					margin: 0px 10px 10px 10px;
					font-weight: bold;
					line-height: 35px;
					display: table;
    				float: right;
				}
			}
			&:nth-child(6){
				a{
					background: $gradient-bg;
					color: white;
					border-radius: 5px;
					margin: 0 10px;
					font-weight: bold;
					line-height: 35px;
					display: table;
    				float: right;
				}
			}
			a {
				text-align: right;
				padding: 0 25px 0;
				line-height: 45px;
			}
		} 
		.top-menu-left{
			padding: 10px 10px 10px 0;
			a{
				height: 41px;
				i{
					padding-top: 7px;
				}
			}
		}
	}
}

@include bp(min-width, $bp-medium + 1) {
	.nav-primary {
		display: inline-block;
		margin-top: 0;
	}
}

/* ============================================ *
 * General Navigation Styles
 * ============================================ */

$nav-primary-height: 80px;

.nav-primary {
	position: relative;
	z-index: 1;

	a {
		text-decoration: none;
		position: relative;
		display: block;
		color: $c-text;
		font-size: 1.2rem;
		line-height: $nav-primary-height;
		font-family: $f-stack-sans;
		@include transition-property(color);
		@include transition-duration(300ms);

		@include bp(max-width, $bp-xxlarge) {
			line-height: $nav-primary-height - 15px;
			font-size: 1.071rem;
		}
	}

	li {
		position: relative;
	}

	li.level1 a {
		border-bottom: none;
		font-size: 13px;
		font-weight: 300;
		line-height: 1;
		padding: 10px 15px 10px 25px;
		@media all and (max-width: 767px) {
			font-size: 1.2rem;
			color: $c-gray;
			background: rgba(0, 0, 0, .1);
		}
	}
}

.header-desplegable .nav-primary {
	a {
		line-height: 60px;
	}
}

.nav-secondary {
	width: 100%;
	@include bp(max-width, $xs-bootstrap) {
		display: none !important;
	}

	li {
		a {
			color: $c-gray;
			font-size: 1rem;
			line-height: 1.2rem;
			font-weight: 300;
			text-decoration: none;
			display: flex;
    		align-items: center;
			@include transition-property(color);
			@include transition-duration(300ms);

			&:hover {
				color: $c-action;
			}

			i {
				margin-right: 5px;
			}
		}
	}
}

.header-desplegable .nav-secondary {
	position: static;
	width: 50%;
}

// Show sub-menus when JS adds the appropriate classes
.nav-primary .menu-active>ul.level0,
.nav-primary li.level0 li.sub-menu-active>ul {
	display: block;
}

/* ============================================ *
 * Small Viewports - Accordion Style Menu
 * ============================================ */

@include bp(max-width, $bp-medium) {

	.nav-primary {

		// Increase clickable area of anchors for small viewports
		a.level0,
		a {
			line-height: $nav-primary-height + 5px;
		}

		// Hide all sub-menus
		li.level0 ul {
			display: none;
		}

		// Indent sub-menus
		li{
			&.level0{
				li{
					padding: 0 0 0 0;
				}
			}
			&.level1{
				a{
					padding: 0 15px 0 25px;
					line-height: 35px;
				}
				li{
					background: rgba(0, 0, 0, 0.04);
				}
			}
		}
		
		li.parent>a:after {
			font-family: "simple-line-icons";
			font-weight: 900;
			content: "\e604";
			font-size: 11px;
			top: 50%;
			right: 15px;
			margin-top: -22px;
			transition: all .5s ease;
			transform: rotate(-90deg);
			position: absolute;
		}

		// Menu active states
		li.parent.sub-menu-active>a:after,
		li.parent.menu-active>a:after {
			transform: rotate(0deg);
			
		}

		li.menu-active>a,
		li.sub-menu-active>a {
			color: $gradient-first-color;
		}
	}
}

/* ============================================ *
 * Large Viewports - Dropdown Menu
 * ============================================ */
.nav-primary{
	@include bp(min-width, $bp-medium + 1){
		ul.level0,
		ul.level1,
		ul.level2{
			padding: 5px 0;
		}
		.level1.view-all,
		.level2.view-all,
		.level3.view-all{
			display: none;
		}
	} 
	.view-all{
		a{
			font-weight: 400;
		}
	}
}


@include bp(min-width, $bp-medium + 1) {

	.nav-primary {
		margin: 0 -15px;
		a.level0 {
			padding: 3px 10px;
			&.has-children {
				padding-right: 25px;
				&:after {
					content: "";
					position: absolute;
					width: 6px;
					height: 6px;
					right: 10px;
					top: 0;
					bottom: 0;
					margin: auto;
					border-bottom: 1px solid white;
					border-right: 1px solid white;
					transform: rotate(45deg);
				}
			}
		}

		li,
		li.level0,
		a.level0 {
			width: auto;
			display: inline-block;
			line-height: 40px;
			font-size: 13px;
			color: #ffffff;
			font-weight: 400;
			text-transform: uppercase;
		}

		.level1 {
			display: block;
			a.level1 {
				font-size: 12px;
				font-weight: normal;
				padding-right: 2em;
			}
		}
		.level2 {
			display: block;
			a.level2 {
				font-size: 12px;
				font-weight: normal;
			}
		}

		li:last-child>a {
			border-bottom: none;
		}

		a:hover,
		li:hover>a {
			background-color: $primary-color-dark;
			color: white;
		}
		a.level1:hover,
		li.level1:hover>a {
			background-color: white;
			color: $primary-color;
		}
		a.level2:hover,
		li.level2:hover>a {
			background-color: white;
			color: $primary-color;
		}

		.menu-active {
			z-index: 200;
		}

		/* Default styles for 1+ drop-down menus */
		li.level0 ul {
			@include menu;
			position: absolute;
			left: 0;
			top: 46px;
			z-index: 10;
			width: 250px;
			display: none;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -4px;
			border:none;
			@include bp(max-width, $bp-xxlarge) {
				top: 40px;
			}
		}

		/* Level 2+ */
		li.level1 ul {
			left: 250px;
			top: -5px;
			li{
				display: block;
			}
		}

		/* Class for js to add briefly to evaluate element positioning. */
		li.level0 ul.level0.position-test {
			display: block;
		}

		/* Correct menus that will hang off the page (class added by js) */
		li.level0 ul.level0.spill {
			right: 0;
			left: auto;

			// Sub-menu drop down to the left
			li.level1 ul {
				left: auto;
				right: 50px;
				top: 20px;
			}
		}

		/* Level 1+ */
		li.level0 li.parent>a {
			position: relative;

			&:after {
				font-family: "simple-line-icons";
				font-weight: 900;
				content: "\e604";
				font-size: 9px;
				top: 50%;
				right: 15px;
				margin-top: -4px;
				transition: all .5s ease;
				transform: rotate(-90deg);
				position: absolute;
			}
		}
	}
}

.nav-primary li.menu-active {
	>ul {
		display: block;
	}
}

#header-account{
	.links{
		a{
			text-align: right;
			@include bp(min-width, $xs-bootstrap+1){
				text-align: center;
			}
		}
	}
}
