.logout-container{
    border-radius: 5px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    padding: 100px 20px;
    margin: 10px 0;
    @include bp(min-width, $xs-bootstrap+1){
        margin: 40px 0;
    }
    .icon-container{
        i{
            height: 60px;
            width: 60px;
            background: $c-green;
            color: white;
            border-radius: 50%;
            padding-top: 22px;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .fa-shopping-cart{
            background: $primary-color;
        }
    }
    p{
        font-weight: 300;
    }
}

